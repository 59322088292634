var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "container text-light non-selectable row items-center text-family-brand",
      class: {
        simple: _vm.simple,
        "justify-between": !_vm.simple,
        "justify-center": _vm.simple
      }
    },
    [
      !_vm.simple
        ? _c("div", [
            _c("img", {
              staticClass: "vertical-middle logo",
              class: { "no-margin-right": _vm.hideWordmark },
              attrs: {
                src: require("assets/letsbutterfly-logo-lite.png"),
                alt: "letsbutterfly logo"
              }
            }),
            !_vm.hideWordmark
              ? _c("img", {
                  staticClass: "vertical-middle gt-xs",
                  staticStyle: { height: "22px" },
                  attrs: {
                    src: require("assets/letsbutterfly-wordmark.png"),
                    alt: "letsbutterfly wordmark"
                  }
                })
              : _vm._e(),
            _vm.subtitle
              ? _c(
                  "span",
                  {
                    staticClass:
                      "subtitle text-family-brand text-weight-regular"
                  },
                  [_vm._v(_vm._s(_vm.subtitle))]
                )
              : _vm._e()
          ])
        : _vm._e(),
      _c("div", [
        _c(
          "small",
          [
            _vm._v("©" + _vm._s(new Date().getFullYear())),
            !_vm.hideRights
              ? [_vm._v(". " + _vm._s(_vm.$t("LEGAL_RIGHTS")))]
              : _vm._e()
          ],
          2
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }