<template>
  <div class="container text-light non-selectable row items-center text-family-brand" :class="{ simple, 'justify-between': !simple, 'justify-center': simple }">
    <div v-if="!simple">
      <img class="vertical-middle logo" :class="{'no-margin-right': hideWordmark}" src="~assets/letsbutterfly-logo-lite.png" alt="letsbutterfly logo">
      <img v-if="!hideWordmark" class="vertical-middle gt-xs" src="~assets/letsbutterfly-wordmark.png" alt="letsbutterfly wordmark" style="height:22px">
      <span v-if="subtitle" class="subtitle text-family-brand text-weight-regular">{{ subtitle }}</span>
    </div>
    <div>
      <small>&copy;{{ (new Date()).getFullYear() }}<template v-if="!hideRights">. {{ $t('LEGAL_RIGHTS') }}</template></small>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LFooter',
  props: {
    hideWordmark: Boolean,
    hideRights: Boolean,
    subtitle: String,
    simple: Boolean
  },
  data () {
    return { }
  }
}
</script>

<style lang="stylus" scoped>
.container
  padding-top 20px
  margin-top 40px
  border-top 1px solid #eee
  &.simple
    margin 0
    border-top 0
    padding 40px 0px 20px 0px
  .logo
    height 44px
    margin 0
    margin-right 8px
  .subtitle
    margin-left 4px

@media only screen and (max-device-width: 375px)
  .container
    div
      width 100%
      text-align center
      .logo
        margin-bottom 8px
</style>
