var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.ready
    ? _c(
        "div",
        [
          _c(
            "q-layout-header",
            { attrs: { id: "productsEditHeader" } },
            [
              _c(
                "q-toolbar",
                { attrs: { inverted: "" } },
                [
                  _c("q-toolbar-title", [
                    _c(
                      "div",
                      {
                        staticClass: "text-center cursor-pointer",
                        staticStyle: { "padding-top": "13px" },
                        on: {
                          click: function($event) {
                            return _vm.processCardSelection(
                              "services-0-online-0"
                            )
                          }
                        }
                      },
                      [
                        _c("q-spinner-puff", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.product.data.channel.online,
                              expression: "product.data.channel.online"
                            }
                          ],
                          staticClass: "absolute",
                          staticStyle: {
                            "z-index": "1",
                            top: "0px",
                            left: "calc(50% - 1.5em + 0px)"
                          },
                          attrs: { color: "gold", size: "3em" }
                        }),
                        _c("img", {
                          staticClass: "relative z-top",
                          class: { pulse: _vm.product.data.channel.online },
                          attrs: {
                            src: "/statics/icons/icon-gold.svg",
                            width: "66"
                          }
                        }),
                        _c(
                          "span",
                          {
                            staticClass:
                              "title-value text-family-brand text-weight-regular text-gold block"
                          },
                          [
                            _c("sup", { staticClass: "block" }, [_vm._v(" ")]),
                            _c(
                              "span",
                              {
                                staticClass:
                                  "text-subinfo text-weight-bold capitalize"
                              },
                              [
                                _vm.product.data.channel.online === 1
                                  ? _c("span", [_vm._v(_vm._s(_vm.$t("LIVE")))])
                                  : _c("span", [
                                      _vm._v(_vm._s(_vm.$t("OFFLINE.LABEL")))
                                    ])
                              ]
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "text-weight-medium block",
                        staticStyle: { "margin-top": "10px" },
                        attrs: { slot: "subtitle" },
                        slot: "subtitle"
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "block title-subject text-black no-text-transform overflow-hidden"
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.product.data.business.name) +
                                "\n          "
                            )
                          ]
                        ),
                        _c("span", {
                          staticClass:
                            "block text-weight-semibold text-subinfo uppercase overflow-hidden",
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.product.data.business.address.html
                            )
                          }
                        })
                      ]
                    )
                  ]),
                  _c(
                    "q-btn",
                    {
                      staticClass: "absolute z-top",
                      attrs: { round: "", size: "lg", "text-color": "primary" },
                      on: { click: _vm.exit }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/statics/_demo/arrow_primary.svg",
                          width: "22"
                        }
                      })
                    ]
                  ),
                  _c(
                    "q-btn",
                    {
                      staticClass: "absolute z-top",
                      attrs: { round: "", size: "lg", "text-color": "primary" },
                      on: { click: _vm.qrcode }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/statics/_demo/qrcode_primary.svg",
                          width: "22"
                        }
                      })
                    ]
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "div",
            {
              ref: "productsEditContent",
              staticClass: "layout-padding no-pointer-events",
              staticStyle: { opacity: "0", "padding-top": "0" },
              attrs: { id: "productsEditContent" }
            },
            [
              _c("q-scroll-observable", { on: { scroll: _vm.scrolled } }),
              _c(
                "div",
                { staticClass: "product" },
                [
                  _c(
                    "transition",
                    {
                      attrs: {
                        appear: "",
                        "enter-active-class": "animated fadeInUp animated-d800"
                      }
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "row no-wrap items-center margin-auto-lr relative-position",
                          staticStyle: { "max-width": "400px", "z-index": "1" }
                        },
                        [
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "q-btn-icon-keep-left margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        icon: "ion-share",
                                        color: "empower-light",
                                        "text-color": "empower",
                                        rounded: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          return _vm.share($event)
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(_vm.$t("SHARE")) +
                                          "\n              "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c("q-btn", {
                                    staticClass:
                                      "q-btn-icon-keep-left margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                    attrs: {
                                      color: "empower-light",
                                      "text-color": "empower",
                                      rounded: "",
                                      icon: _vm.product.verified
                                        ? "ion-checkmark-circle"
                                        : "ion-help-circle",
                                      label: _vm.product.verified
                                        ? _vm.$t("CLAIM.BUSINESS_CLAIMED")
                                        : _vm.$t("CLAIM.BUSINESS")
                                    },
                                    nativeOn: {
                                      click: function($event) {
                                        return _vm.claim($event)
                                      }
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ]
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row no-wrap items-center margin-auto-lr relative-position",
                      staticStyle: { "max-width": "400px", "z-index": "1" }
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "protect",
                                    "text-color": "white",
                                    rounded: ""
                                  },
                                  on: { click: _vm.channelRequestsClear }
                                },
                                [
                                  _vm._v(
                                    "\n              Reset   Queue\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.requests.length
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "col justify-between items-center margin-auto-lr datagroup-container"
                        },
                        [
                          _c(
                            "transition",
                            {
                              attrs: {
                                appear: "",
                                "enter-active-class":
                                  "animated fadeInUp animated-d800"
                              }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "full-width title-group text-family-brand text-weight-bolder text-center uppercase"
                                },
                                [_vm._v("\n            Customers\n          ")]
                              )
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "row justify-between",
                              staticStyle: { "margin-top": "-60px" }
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "cols",
                                  staticStyle: { width: "50%" }
                                },
                                [
                                  _vm._m(0),
                                  _vm._l(_vm.requests, function(request, ix) {
                                    return [
                                      !request.status ||
                                      request.status === "in-review"
                                        ? _c(
                                            "transition",
                                            {
                                              key:
                                                "product-requests-" +
                                                ix +
                                                "-" +
                                                request.status,
                                              attrs: {
                                                appear: "",
                                                "enter-active-class":
                                                  "animated fadeInUp animated-d800"
                                              }
                                            },
                                            [
                                              _c(
                                                "q-card",
                                                {
                                                  directives: [
                                                    {
                                                      name: "touch-pan",
                                                      rawName:
                                                        "v-touch-pan.noMouse",
                                                      value: function(obj) {
                                                        _vm.setCardIntent(
                                                          obj,
                                                          "request-" +
                                                            ix +
                                                            "-" +
                                                            request.status,
                                                          function() {
                                                            _vm.requestView(
                                                              ix,
                                                              request
                                                            )
                                                          }
                                                        )
                                                      },
                                                      expression:
                                                        "(obj) => { setCardIntent(obj, `request-${ix}-${request.status}`, () => { requestView(ix, request) }) }",
                                                      modifiers: {
                                                        noMouse: true
                                                      }
                                                    }
                                                  ],
                                                  ref:
                                                    "product-card-request-" +
                                                    ix +
                                                    "-" +
                                                    request.status,
                                                  refInFor: true,
                                                  staticClass:
                                                    "overflow-hidden q-card-grouped q-card-widget",
                                                  staticStyle: {
                                                    "max-width": "100%"
                                                  },
                                                  attrs: { inline: "" }
                                                },
                                                [
                                                  _c("q-card-title", [
                                                    _c("img", {
                                                      staticClass:
                                                        "avatar float-left",
                                                      staticStyle: {
                                                        width: "80px",
                                                        height: "80px",
                                                        "border-radius": "17px",
                                                        "border-bottom-right-radius":
                                                          "0"
                                                      },
                                                      attrs: {
                                                        src: request.item.photo
                                                      }
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "float-left"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "block on-right-sm capitalize text-weight-bold font-size-120p"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  request.info.name.replace(
                                                                    /\_/g,
                                                                    " "
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "block on-right-sm"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "columns flex justify-between",
                                                                staticStyle: {
                                                                  height: "48px"
                                                                }
                                                              },
                                                              [
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col-1"
                                                                  },
                                                                  [
                                                                    _c("img", {
                                                                      staticClass:
                                                                        "brighten-50 dark-img-invert-50 vertical-middle on-left-sm",
                                                                      attrs: {
                                                                        src:
                                                                          "/statics/_demo/station.person.svg",
                                                                        height:
                                                                          "30"
                                                                      }
                                                                    })
                                                                  ]
                                                                ),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "col"
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "em",
                                                                      {
                                                                        staticStyle: {
                                                                          padding:
                                                                            "0",
                                                                          top:
                                                                            "-7px",
                                                                          position:
                                                                            "relative"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "with"
                                                                        )
                                                                      ]
                                                                    ),
                                                                    _c("br"),
                                                                    _c(
                                                                      "b",
                                                                      {
                                                                        staticClass:
                                                                          "uppercase font-size-80p",
                                                                        staticStyle: {
                                                                          color:
                                                                            "#004BA3",
                                                                          position:
                                                                            "relative",
                                                                          top:
                                                                            "-23px"
                                                                        }
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            request.location
                                                                          )
                                                                        )
                                                                      ]
                                                                    )
                                                                  ]
                                                                )
                                                              ]
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "right"
                                                        },
                                                        slot: "right"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              position:
                                                                "relative",
                                                              top: "-22px"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "block font-size-120p text-family-brand text-primary text-right text-weight-bold"
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-subinfo-l"
                                                                  },
                                                                  [_vm._v("#")]
                                                                ),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    request.id
                                                                  ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "q-chip",
                                                              {
                                                                staticClass:
                                                                  "q-chip-info on-right-xs text-family-brand",
                                                                attrs: {
                                                                  dense: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      request.status.replace(
                                                                        /\_/g,
                                                                        " "
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _c("q-card-separator", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !_vm.$q.platform
                                                          .has.touch,
                                                        expression:
                                                          "!$q.platform.has.touch"
                                                      }
                                                    ]
                                                  }),
                                                  _c(
                                                    "q-card-actions",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: !_vm.$q
                                                            .platform.has.touch,
                                                          expression:
                                                            "!$q.platform.has.touch"
                                                        }
                                                      ]
                                                    },
                                                    [
                                                      _c(
                                                        "q-btn",
                                                        {
                                                          staticClass:
                                                            "full-width",
                                                          attrs: {
                                                            color: "primary",
                                                            flat: "",
                                                            rounded: "",
                                                            label: _vm.$t(
                                                              "EDIT"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.requestView(
                                                                ix,
                                                                request
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "q-tooltip",
                                                            {
                                                              attrs: {
                                                                delay: 2000,
                                                                inverted: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      _vm.getEcosystemLabel(
                                                                        "EDIT.LABEL_TT"
                                                                      )
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ],
                                2
                              ),
                              _c(
                                "div",
                                {
                                  staticClass: "cols",
                                  staticStyle: { width: "50%" }
                                },
                                [
                                  _vm._m(1),
                                  _vm._l(_vm.requests, function(request, ix) {
                                    return [
                                      request.status &&
                                      request.status !== "in-review" &&
                                      request.status !== "finalized"
                                        ? _c(
                                            "transition",
                                            {
                                              key:
                                                "product-requests-" +
                                                ix +
                                                "-" +
                                                request.status,
                                              attrs: {
                                                appear: "",
                                                "enter-active-class":
                                                  "animated fadeInUp animated-d800"
                                              }
                                            },
                                            [
                                              _c(
                                                "q-card",
                                                {
                                                  directives: [
                                                    {
                                                      name: "touch-pan",
                                                      rawName:
                                                        "v-touch-pan.noMouse",
                                                      value: function(obj) {
                                                        _vm.setCardIntent(
                                                          obj,
                                                          "request-" +
                                                            ix +
                                                            "-" +
                                                            request.status,
                                                          function() {
                                                            _vm.requestView(
                                                              ix,
                                                              request
                                                            )
                                                          }
                                                        )
                                                      },
                                                      expression:
                                                        "(obj) => { setCardIntent(obj, `request-${ix}-${request.status}`, () => { requestView(ix, request) }) }",
                                                      modifiers: {
                                                        noMouse: true
                                                      }
                                                    }
                                                  ],
                                                  ref:
                                                    "product-card-request-" +
                                                    ix +
                                                    "-" +
                                                    request.status,
                                                  refInFor: true,
                                                  staticClass:
                                                    "overflow-hidden q-card-grouped q-card-widget",
                                                  staticStyle: {
                                                    "max-width": "100%"
                                                  },
                                                  attrs: { inline: "" }
                                                },
                                                [
                                                  _c("q-card-title", [
                                                    _c("img", {
                                                      staticClass:
                                                        "avatar float-left",
                                                      staticStyle: {
                                                        width: "80px",
                                                        height: "80px",
                                                        "border-radius": "17px",
                                                        "border-bottom-right-radius":
                                                          "0"
                                                      },
                                                      attrs: {
                                                        src: request.item.photo
                                                      }
                                                    }),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "float-left"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "block on-right-sm capitalize text-weight-bold font-size-120p"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  request.info.name.replace(
                                                                    /\_/g,
                                                                    " "
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        ),
                                                        _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "block on-right-sm"
                                                          },
                                                          [
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "columns flex justify-between",
                                                                staticStyle: {
                                                                  height: "48px"
                                                                }
                                                              },
                                                              [
                                                                request.status ===
                                                                "completed"
                                                                  ? [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-1"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "brighten-50 dark-img-invert-50 vertical-middle on-left-sm",
                                                                              attrs: {
                                                                                src:
                                                                                  "/statics/_demo/checkmark.circle.fill.svg",
                                                                                height:
                                                                                  "30"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "em",
                                                                            {
                                                                              staticStyle: {
                                                                                padding:
                                                                                  "0",
                                                                                top:
                                                                                  "-7px",
                                                                                position:
                                                                                  "relative"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "with"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _c(
                                                                            "b",
                                                                            {
                                                                              staticClass:
                                                                                "uppercase font-size-80p",
                                                                              staticStyle: {
                                                                                color:
                                                                                  "#004BA3",
                                                                                position:
                                                                                  "relative",
                                                                                top:
                                                                                  "-23px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "CUSTOMER"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  : request.status ===
                                                                    "dropping-off"
                                                                  ? [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-1"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "brighten-50 dark-img-invert-50 vertical-middle on-left-sm",
                                                                              attrs: {
                                                                                src:
                                                                                  "/statics/_demo/viewfinder.qrcode.svg",
                                                                                height:
                                                                                  "30"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "em",
                                                                            {
                                                                              staticStyle: {
                                                                                padding:
                                                                                  "0",
                                                                                top:
                                                                                  "-7px",
                                                                                position:
                                                                                  "relative"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "to"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _c(
                                                                            "b",
                                                                            {
                                                                              staticClass:
                                                                                "uppercase font-size-80p",
                                                                              staticStyle: {
                                                                                color:
                                                                                  "#004BA3",
                                                                                position:
                                                                                  "relative",
                                                                                top:
                                                                                  "-23px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "PATIO #1"
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  : [
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col-1"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "img",
                                                                            {
                                                                              staticClass:
                                                                                "brighten-50 dark-img-invert-50 vertical-middle on-left-sm animate-spin",
                                                                              attrs: {
                                                                                src:
                                                                                  "/statics/_demo/station.globe.svg",
                                                                                height:
                                                                                  "30"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      ),
                                                                      _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "col"
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "em",
                                                                            {
                                                                              staticStyle: {
                                                                                padding:
                                                                                  "0",
                                                                                top:
                                                                                  "-7px",
                                                                                position:
                                                                                  "relative"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                "at"
                                                                              )
                                                                            ]
                                                                          ),
                                                                          _c(
                                                                            "br"
                                                                          ),
                                                                          _c(
                                                                            "b",
                                                                            {
                                                                              staticClass:
                                                                                "uppercase font-size-80p",
                                                                              staticStyle: {
                                                                                color:
                                                                                  "#004BA3",
                                                                                position:
                                                                                  "relative",
                                                                                top:
                                                                                  "-23px"
                                                                              }
                                                                            },
                                                                            [
                                                                              _vm._v(
                                                                                _vm._s(
                                                                                  request.info.station.replace(
                                                                                    /\_/g,
                                                                                    " "
                                                                                  )
                                                                                )
                                                                              )
                                                                            ]
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                              ],
                                                              2
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        attrs: {
                                                          slot: "right"
                                                        },
                                                        slot: "right"
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              position:
                                                                "relative",
                                                              top: "-22px"
                                                            }
                                                          },
                                                          [
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "block font-size-120p text-family-brand text-primary text-right text-weight-bold"
                                                              },
                                                              [
                                                                _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-subinfo-l"
                                                                  },
                                                                  [_vm._v("#")]
                                                                ),
                                                                _vm._v(
                                                                  _vm._s(
                                                                    request.id
                                                                  ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            ),
                                                            _c(
                                                              "q-chip",
                                                              {
                                                                staticClass:
                                                                  "on-right-xs text-family-brand",
                                                                class: {
                                                                  "q-chip-hint":
                                                                    request.status ===
                                                                    "in-progress",
                                                                  "q-chip-pro-fill":
                                                                    request.status ===
                                                                    "dropping-off",
                                                                  "q-chip-educate":
                                                                    request.status ===
                                                                    "completed"
                                                                },
                                                                attrs: {
                                                                  dense: ""
                                                                }
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "\n                          " +
                                                                    _vm._s(
                                                                      request.status.replace(
                                                                        /\_/g,
                                                                        " "
                                                                      )
                                                                    ) +
                                                                    "\n                        "
                                                                )
                                                              ]
                                                            )
                                                          ],
                                                          1
                                                        )
                                                      ]
                                                    )
                                                  ]),
                                                  _c("q-card-separator", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !_vm.$q.platform
                                                          .has.touch,
                                                        expression:
                                                          "!$q.platform.has.touch"
                                                      }
                                                    ]
                                                  }),
                                                  _c(
                                                    "q-card-actions",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: !_vm.$q
                                                            .platform.has.touch,
                                                          expression:
                                                            "!$q.platform.has.touch"
                                                        }
                                                      ]
                                                    },
                                                    [
                                                      _c(
                                                        "q-btn",
                                                        {
                                                          staticClass:
                                                            "full-width",
                                                          attrs: {
                                                            color: "primary",
                                                            flat: "",
                                                            rounded: "",
                                                            label: _vm.$t(
                                                              "EDIT"
                                                            )
                                                          },
                                                          on: {
                                                            click: function(
                                                              $event
                                                            ) {
                                                              return _vm.requestView(
                                                                ix,
                                                                request
                                                              )
                                                            }
                                                          }
                                                        },
                                                        [
                                                          _c(
                                                            "q-tooltip",
                                                            {
                                                              attrs: {
                                                                delay: 2000,
                                                                inverted: ""
                                                              }
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                        " +
                                                                  _vm._s(
                                                                    _vm.$t(
                                                                      _vm.getEcosystemLabel(
                                                                        "EDIT.LABEL_TT"
                                                                      )
                                                                    )
                                                                  ) +
                                                                  "\n                      "
                                                              )
                                                            ]
                                                          )
                                                        ],
                                                        1
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                1
                                              )
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ]
                                  })
                                ],
                                2
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      staticClass:
                        "row justify-between items-center margin-auto-lr datagroup-container"
                    },
                    [
                      _c(
                        "transition",
                        {
                          attrs: {
                            appear: "",
                            "enter-active-class":
                              "animated fadeInUp animated-d800"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "full-width title-group text-family-brand text-weight-bolder text-center uppercase"
                            },
                            [_vm._v("\n            Controls\n          ")]
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._l(_vm.datagroups, function(datagroup, index) {
                    return [
                      !datagroup.types ||
                      (datagroup.types &&
                        datagroup.types.filter(function(el) {
                          return _vm.product.data.business.types.includes(el)
                        }).length)
                        ? _c(
                            "div",
                            {
                              key:
                                "product-datagroup-" +
                                datagroup.id +
                                "-" +
                                index,
                              staticClass:
                                "row justify-between items-center margin-auto-lr datagroup-container"
                            },
                            [
                              _c(
                                "transition",
                                {
                                  attrs: {
                                    appear: "",
                                    "enter-active-class":
                                      "animated fadeInUp animated-d800"
                                  }
                                },
                                [
                                  datagroup.id !== "services"
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "full-width title-group text-family-brand text-weight-bolder text-center uppercase",
                                          class: { "on-top-xx": index > 0 }
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t(
                                                datagroup.id.toUpperCase() +
                                                  ".LABEL_TT"
                                              )
                                            )
                                          )
                                        ]
                                      )
                                    : _vm._e()
                                ]
                              ),
                              _vm._l(datagroup.datafields, function(
                                datafield,
                                ix
                              ) {
                                return [
                                  datafield.archive !== true
                                    ? _c(
                                        "transition",
                                        {
                                          key:
                                            "product-datagroup-" +
                                            datagroup.id +
                                            "-" +
                                            index +
                                            "-" +
                                            datafield.id,
                                          attrs: {
                                            appear: "",
                                            "enter-active-class":
                                              "animated fadeInUp animated-d800"
                                          }
                                        },
                                        [
                                          (!datafield.types ||
                                            datafield.types.filter(function(
                                              el
                                            ) {
                                              return _vm.product.data.business.types.includes(
                                                el
                                              )
                                            }).length) &&
                                          (!datafield.notTypes ||
                                            !datafield.notTypes.filter(function(
                                              el
                                            ) {
                                              return _vm.product.data.business.types.includes(
                                                el
                                              )
                                            }).length)
                                            ? _c(
                                                "q-card",
                                                {
                                                  directives: [
                                                    {
                                                      name: "touch-pan",
                                                      rawName:
                                                        "v-touch-pan.noMouse",
                                                      value: function(obj) {
                                                        _vm.setCardIntent(
                                                          obj,
                                                          [
                                                            datagroup.id,
                                                            index,
                                                            datafield.id,
                                                            ix
                                                          ].join("-"),
                                                          _vm.processCardSelection
                                                        )
                                                      },
                                                      expression:
                                                        "(obj) => { setCardIntent(obj, [datagroup.id, index, datafield.id, ix].join('-'), processCardSelection) }",
                                                      modifiers: {
                                                        noMouse: true
                                                      }
                                                    }
                                                  ],
                                                  ref:
                                                    "product-card-" +
                                                    [
                                                      datagroup.id,
                                                      index,
                                                      datafield.id,
                                                      ix
                                                    ].join("-"),
                                                  refInFor: true,
                                                  staticClass:
                                                    "overflow-hidden q-card-grouped q-card-widget",
                                                  attrs: {
                                                    inline: "",
                                                    "data-index": [
                                                      datagroup.id,
                                                      index,
                                                      datafield.id,
                                                      ix
                                                    ].join("-"),
                                                    disabled:
                                                      datafield.indicates &&
                                                      datafield.indicates
                                                        .requiresValue &&
                                                      !datafield.indicates.aux()
                                                        .value
                                                  }
                                                },
                                                [
                                                  datafield.indicates &&
                                                  datafield.indicates.indicator
                                                    ? [
                                                        datafield.indicates.indicator()
                                                          ? _c("q-progress", {
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "-4px"
                                                              },
                                                              attrs: {
                                                                stripe: "",
                                                                indeterminate:
                                                                  "",
                                                                color:
                                                                  "educate",
                                                                height: "4px"
                                                              }
                                                            })
                                                          : _c("q-progress", {
                                                              staticStyle: {
                                                                "margin-bottom":
                                                                  "-4px"
                                                              },
                                                              attrs: {
                                                                percentage: 100,
                                                                stripe: "",
                                                                color:
                                                                  "protect",
                                                                height: "4px"
                                                              }
                                                            })
                                                      ]
                                                    : datafield.automated ===
                                                        true &&
                                                      _vm.automationEnabled ===
                                                        true
                                                    ? _c("q-progress", {
                                                        staticStyle: {
                                                          "margin-bottom":
                                                            "-4px"
                                                        },
                                                        attrs: {
                                                          stripe: "",
                                                          indeterminate: "",
                                                          color: "educate",
                                                          height: "4px"
                                                        }
                                                      })
                                                    : _vm._e(),
                                                  _c("q-card-title", [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "opacity-9 float-left on-left-sm on-bottom-lg overflow-hidden text-center",
                                                        staticStyle: {
                                                          width: "33px",
                                                          height: "33px"
                                                        }
                                                      },
                                                      [
                                                        _c("img", {
                                                          class: {
                                                            "filter-invert-100":
                                                              _vm.anyDarkmode
                                                          },
                                                          staticStyle: {
                                                            "max-height": "24px"
                                                          },
                                                          attrs: {
                                                            src:
                                                              "/statics/_demo/" +
                                                              datafield.icon +
                                                              ".svg",
                                                            width: "24"
                                                          }
                                                        })
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "title-label block text-primary q-caption text-family-brand text-weight-semibold uppercase"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                    " +
                                                            _vm._s(
                                                              _vm.$t(
                                                                [
                                                                  datafield.id,
                                                                  "LABEL"
                                                                ]
                                                                  .join(".")
                                                                  .toUpperCase()
                                                              )
                                                            ) +
                                                            "\n                    "
                                                        ),
                                                        datafield.automated ===
                                                          true &&
                                                        _vm.automationEnabled ===
                                                          true
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "absolute uppercase chip-live",
                                                                staticStyle: {
                                                                  top: "10px",
                                                                  right: "10px",
                                                                  width:
                                                                    "auto !important"
                                                                }
                                                              },
                                                              [_vm._v("LIVE")]
                                                            )
                                                          : datafield.automated ===
                                                              true &&
                                                            _vm.automationEnabled ===
                                                              false
                                                          ? _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "absolute uppercase chip-offline",
                                                                staticStyle: {
                                                                  top: "10px",
                                                                  right: "10px",
                                                                  width:
                                                                    "auto !important"
                                                                }
                                                              },
                                                              [_vm._v("OFF")]
                                                            )
                                                          : _vm._e()
                                                      ]
                                                    ),
                                                    datafield.signal &&
                                                    datafield.signal.descriptor()
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "title-main on-top-sm block line-height-sm float-left"
                                                          },
                                                          [
                                                            datafield.indicates &&
                                                            datafield.indicates
                                                              .requiresValue &&
                                                            !datafield.indicates.aux()
                                                              .value
                                                              ? _c(
                                                                  "span",
                                                                  {
                                                                    staticClass:
                                                                      "text-value"
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                        "
                                                                    ),
                                                                    _c(
                                                                      "q-icon",
                                                                      {
                                                                        staticClass:
                                                                          "text-warning on-left",
                                                                        attrs: {
                                                                          name:
                                                                            "ion-warning",
                                                                          size:
                                                                            "22px"
                                                                        }
                                                                      }
                                                                    ),
                                                                    _vm._v(
                                                                      " " +
                                                                        _vm._s(
                                                                          _vm.$t(
                                                                            "NOT_FOUND"
                                                                          )
                                                                        ) +
                                                                        "\n                    "
                                                                    )
                                                                  ],
                                                                  1
                                                                )
                                                              : _c(
                                                                  "span",
                                                                  [
                                                                    datafield.signal.bCheck() !==
                                                                    null
                                                                      ? [
                                                                          _vm._v(
                                                                            "\n                          "
                                                                          ),
                                                                          _c(
                                                                            "q-icon",
                                                                            {
                                                                              staticClass:
                                                                                "text-educate on-left",
                                                                              class:
                                                                                datafield.signal.bCheck() ===
                                                                                false
                                                                                  ? "text-shadow-l"
                                                                                  : "text-educate",
                                                                              attrs: {
                                                                                name:
                                                                                  datafield.signal.bCheck() ===
                                                                                  false
                                                                                    ? "ion-close-circle"
                                                                                    : "ion-checkmark-circle",
                                                                                size:
                                                                                  "22px"
                                                                              }
                                                                            }
                                                                          )
                                                                        ]
                                                                      : _vm._e(),
                                                                    _vm._v(
                                                                      "\n                      " +
                                                                        _vm._s(
                                                                          datafield.signal.descriptor()
                                                                        ) +
                                                                        "\n                    "
                                                                    )
                                                                  ],
                                                                  2
                                                                )
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                    datafield.indicates &&
                                                    datafield.indicates.aux
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "text-right text-family-brand text-weight-semibold absolute-top-right on-left-lg on-top-lgr lowercase ellipsis q-card-title-aux",
                                                            class:
                                                              datafield.indicates.aux()
                                                                .state === 1
                                                                ? "text-educate"
                                                                : "text-attention",
                                                            attrs: {
                                                              slot: "right"
                                                            },
                                                            slot: "right"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                      " +
                                                                _vm._s(
                                                                  datafield.indicates.aux()
                                                                    .text
                                                                ) +
                                                                "\n                  "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]),
                                                  _c("q-card-separator", {
                                                    directives: [
                                                      {
                                                        name: "show",
                                                        rawName: "v-show",
                                                        value: !_vm.$q.platform
                                                          .has.touch,
                                                        expression:
                                                          "!$q.platform.has.touch"
                                                      }
                                                    ]
                                                  }),
                                                  _c(
                                                    "q-card-actions",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: !_vm.$q
                                                            .platform.has.touch,
                                                          expression:
                                                            "!$q.platform.has.touch"
                                                        }
                                                      ]
                                                    },
                                                    [
                                                      datafield.paidFeature &&
                                                      (!_vm.product.data
                                                        .paidFeatures ||
                                                        !_vm.product.data
                                                          .paidFeatures[
                                                          datafield.paidFeature
                                                        ])
                                                        ? _c(
                                                            "q-btn",
                                                            {
                                                              staticClass:
                                                                "full-width",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                flat: "",
                                                                rounded: "",
                                                                label: _vm.$t(
                                                                  "ENABLE"
                                                                )
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "q-tooltip",
                                                                {
                                                                  attrs: {
                                                                    delay: 2000,
                                                                    inverted: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          _vm.getEcosystemLabel(
                                                                            "ENABLE.LABEL_TT"
                                                                          )
                                                                        )
                                                                      ) +
                                                                      "\n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                        : _c(
                                                            "q-btn",
                                                            {
                                                              staticClass:
                                                                "full-width",
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                flat: "",
                                                                rounded: "",
                                                                label: _vm.$t(
                                                                  "EDIT"
                                                                )
                                                              },
                                                              on: {
                                                                click: function(
                                                                  $event
                                                                ) {
                                                                  _vm.processCardSelection(
                                                                    [
                                                                      datagroup.id,
                                                                      index,
                                                                      datafield.id,
                                                                      ix
                                                                    ].join("-")
                                                                  )
                                                                }
                                                              }
                                                            },
                                                            [
                                                              _c(
                                                                "q-tooltip",
                                                                {
                                                                  attrs: {
                                                                    delay: 2000,
                                                                    inverted: ""
                                                                  }
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                      " +
                                                                      _vm._s(
                                                                        _vm.$t(
                                                                          _vm.getEcosystemLabel(
                                                                            "EDIT.LABEL_TT"
                                                                          )
                                                                        )
                                                                      ) +
                                                                      "\n                    "
                                                                  )
                                                                ]
                                                              )
                                                            ],
                                                            1
                                                          )
                                                    ],
                                                    1
                                                  )
                                                ],
                                                2
                                              )
                                            : _vm._e()
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ]
                              }),
                              datagroup.actions && datagroup.actions.add
                                ? [
                                    datagroup.datafields.length
                                      ? _c("div", {
                                          key:
                                            "product-datagroup-" +
                                            datagroup.id +
                                            "-" +
                                            index +
                                            "-actions-break",
                                          staticClass: "flex-break"
                                        })
                                      : _vm._e(),
                                    _c(
                                      "transition",
                                      {
                                        directives: [
                                          {
                                            name: "key",
                                            rawName: "v-key",
                                            value:
                                              "product-datagroup-" +
                                              datagroup.id +
                                              "-" +
                                              index +
                                              "-actions-add",
                                            expression:
                                              "`product-datagroup-${datagroup.id}-${index}-actions-add`"
                                          }
                                        ],
                                        attrs: {
                                          appear: "",
                                          "enter-active-class":
                                            "animated fadeInUp animated-d800"
                                        }
                                      },
                                      [
                                        _c(
                                          "q-card",
                                          {
                                            staticClass:
                                              "full-width limit-width-100p no-background no-border no-shadow text-center"
                                          },
                                          [
                                            _c(
                                              "q-card-main",
                                              {
                                                staticClass:
                                                  "column justify-center full-height no-padding-top"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "col-xs-auto"
                                                  },
                                                  [
                                                    _c("q-btn", {
                                                      staticClass:
                                                        "limit-width-420 full-width text-family-brand text-weight-semibold",
                                                      attrs: {
                                                        color:
                                                          "secondary-light",
                                                        "text-color":
                                                          "secondary",
                                                        rounded: "",
                                                        label: _vm.$t("ADD")
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return datagroup.actions.add(
                                                            datagroup
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e(),
                              datagroup.actions && datagroup.actions.remove
                                ? [
                                    datagroup.datafields.length
                                      ? _c("div", {
                                          key:
                                            "product-datagroup-" +
                                            datagroup.id +
                                            "-" +
                                            index +
                                            "-actions-break",
                                          staticClass: "flex-break"
                                        })
                                      : _vm._e(),
                                    _c(
                                      "transition",
                                      {
                                        directives: [
                                          {
                                            name: "key",
                                            rawName: "v-key",
                                            value:
                                              "product-datagroup-" +
                                              datagroup.id +
                                              "-" +
                                              index +
                                              "-actions-remove",
                                            expression:
                                              "`product-datagroup-${datagroup.id}-${index}-actions-remove`"
                                          }
                                        ],
                                        attrs: {
                                          appear: "",
                                          "enter-active-class":
                                            "animated fadeInUp animated-d800"
                                        }
                                      },
                                      [
                                        _c(
                                          "q-card",
                                          {
                                            staticClass:
                                              "full-width limit-width-100p no-background no-border no-shadow text-center"
                                          },
                                          [
                                            _c(
                                              "q-card-main",
                                              {
                                                staticClass:
                                                  "column justify-center full-height no-padding-top"
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass: "col-xs-auto"
                                                  },
                                                  [
                                                    _c("q-btn", {
                                                      staticClass:
                                                        "limit-width-420 full-width text-family-brand text-weight-semibold uppercase",
                                                      attrs: {
                                                        color:
                                                          "protect-gradient",
                                                        "text-color": "white",
                                                        rounded: "",
                                                        label: _vm.$t("REMOVE")
                                                      },
                                                      on: {
                                                        click: function(
                                                          $event
                                                        ) {
                                                          return datagroup.actions.remove(
                                                            datagroup
                                                          )
                                                        }
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                : _vm._e()
                            ],
                            2
                          )
                        : _vm._e()
                    ]
                  })
                ],
                2
              )
            ],
            1
          ),
          _c(
            "q-page-sticky",
            { attrs: { position: "bottom-right", offset: [20, 20] } },
            [
              _c(
                "transition",
                {
                  attrs: {
                    appear: "",
                    "enter-active-class": "animated fadeInUp animated400",
                    "leave-active-class": "animated fadeOutDown animated400"
                  }
                },
                [
                  _c(
                    "q-btn",
                    {
                      directives: [{ name: "ripple", rawName: "v-ripple" }],
                      staticClass: "shadow-24",
                      attrs: {
                        size: "lg",
                        round: "",
                        push: "",
                        color: "primary"
                      },
                      on: { click: _vm.automationSettings }
                    },
                    [
                      _c("q-icon", {
                        attrs: {
                          name: "ion-code-working",
                          size: "44px",
                          color: "white"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogRequest", position: "bottom" },
              model: {
                value: _vm.dialogRequestShow,
                callback: function($$v) {
                  _vm.dialogRequestShow = $$v
                },
                expression: "dialogRequestShow"
              }
            },
            [
              _vm.dialogRequestIndex !== null &&
              _vm.requests[_vm.dialogRequestIndex]
                ? _c(
                    "q-modal-layout",
                    [
                      _c(
                        "q-toolbar",
                        {
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [
                          _c("q-toolbar-title", [
                            _vm._v(
                              _vm._s(
                                _vm.requests[
                                  _vm.dialogRequestIndex
                                ].info.name.replace(/\_/g, " ")
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "q-toolbar",
                        {
                          staticClass: "toolbar-overscroll-shadow",
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "primary-light",
                                        "text-color": "primary",
                                        rounded: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialogRequestShow = false
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("DONE")) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "text-family-brand layout-padding no-padding-tb row justify-center items-center",
                          staticStyle: { "max-width": "500px" }
                        },
                        [
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "shadow-l",
                                        "text-color": "black",
                                        rounded: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.requestStatusUpdate(
                                            "in-review"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              In-Review\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "standout",
                                        "text-color": "white",
                                        rounded: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.requestStatusUpdate(
                                            "in-progress"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              In-Progress\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "attention",
                                        "text-color": "white",
                                        rounded: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.requestStatusUpdate(
                                            "dropping-off"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Dropping-off\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "educate",
                                        "text-color": "white",
                                        rounded: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.requestStatusUpdate(
                                            "completed"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Completed\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "purple",
                                        "text-color": "white",
                                        rounded: ""
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.requestStatusUpdate(
                                            "finalize"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              Finalize / Archive\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogShare", position: "bottom" },
              model: {
                value: _vm.dialogShareShow,
                callback: function($$v) {
                  _vm.dialogShareShow = $$v
                },
                expression: "dialogShareShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                {
                  staticStyle: {
                    "background-image":
                      "url(/statics/_demo/square.and.arrow.up.fill_primary.svg)"
                  }
                },
                [
                  _c(
                    "q-toolbar",
                    { attrs: { slot: "header", inverted: "" }, slot: "header" },
                    [_c("q-toolbar-title", [_vm._v(_vm._s(_vm.$t("SHARE")))])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.dialogShareShow = false
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("DONE")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding no-padding-top" },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c(
                        "q-list",
                        {
                          staticClass: "text-family-brand",
                          attrs: {
                            "no-border": "",
                            link: "",
                            dark: _vm.anyDarkmode
                          }
                        },
                        [
                          _c(
                            "p",
                            {
                              staticClass:
                                "q-title layout-padding no-padding-top text-subinfo slideInDown",
                              staticStyle: { "padding-bottom": "20px" }
                            },
                            [
                              _vm._v(
                                "\n            Share this channel using any of the following methods and platforms for everyone to view.\n          "
                              )
                            ]
                          ),
                          _vm.shareSheetSupport()
                            ? _c(
                                "q-item",
                                {
                                  attrs: { item: "" },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.shareSheet($event)
                                    }
                                  }
                                },
                                [
                                  _c("q-item-main", {
                                    staticClass:
                                      "font-size-160p text-weight-semibold",
                                    attrs: { label: _vm.$t("SHARESHEET.LABEL") }
                                  }),
                                  _c(
                                    "q-item-side",
                                    { staticClass: "text-center on-top-sm" },
                                    [
                                      _c("img", {
                                        class: {
                                          "filter-invert-80": _vm.anyDarkmode
                                        },
                                        staticStyle: { height: "33px" },
                                        attrs: {
                                          src:
                                            "/statics/_demo/rectangle.stack.fill.svg"
                                        }
                                      })
                                    ]
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "q-item",
                            {
                              attrs: { item: "" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.openURL(_vm.productFullURI)
                                }
                              }
                            },
                            [
                              _c("q-item-main", {
                                staticClass:
                                  "font-size-160p text-weight-semibold",
                                attrs: { label: _vm.$t("WEBLINK.LABEL") }
                              }),
                              _c(
                                "q-item-side",
                                { staticClass: "text-center on-top-sm" },
                                [
                                  _c("img", {
                                    staticStyle: { height: "33px" },
                                    attrs: {
                                      src:
                                        "/statics/_demo/square.and.arrow.up.fill_primary.svg"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            {
                              attrs: { item: "" },
                              nativeOn: {
                                click: function($event) {
                                  return _vm.qrcode($event)
                                }
                              }
                            },
                            [
                              _c("q-item-main", {
                                staticClass:
                                  "font-size-160p text-weight-semibold",
                                attrs: { label: _vm.$t("QRCODE.LABEL") }
                              }),
                              _c(
                                "q-item-side",
                                { staticClass: "text-center on-top-sm" },
                                [
                                  _c("img", {
                                    staticStyle: { height: "33px" },
                                    attrs: {
                                      src: "/statics/_demo/qrcode_primary.svg"
                                    }
                                  })
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogQRCode", position: "bottom" },
              model: {
                value: _vm.dialogQRCodeShow,
                callback: function($$v) {
                  _vm.dialogQRCodeShow = $$v
                },
                expression: "dialogQRCodeShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                {
                  staticStyle: {
                    "background-image": "url(/statics/_demo/qrcode_primary.svg)"
                  }
                },
                [
                  _c(
                    "q-toolbar",
                    { attrs: { slot: "header", inverted: "" }, slot: "header" },
                    [
                      _c("q-toolbar-title", [
                        _vm._v(_vm._s(_vm.$t("QRCODE.LABEL")))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    loading: _vm.dialogQRCodeDownloading,
                                    disabled: !_vm.channel.qrcode_ref,
                                    color: "empower-light",
                                    "text-color": "empower",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.qrcodeDownload($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("DOWNLOAD")) +
                                      "\n              "
                                  ),
                                  _c("q-spinner-puff", {
                                    attrs: { slot: "loading", size: "20px" },
                                    slot: "loading"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.dialogQRCodeShow = false
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("DONE")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "layout-padding no-padding-top text-center"
                    },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c(
                        "transition",
                        {
                          attrs: {
                            appear: "",
                            "enter-active-class":
                              "animated fadeInUp animated-d800"
                          }
                        },
                        [
                          _vm.channel.qrcode_ref
                            ? _c("img", {
                                staticStyle: {
                                  width: "95%",
                                  "border-radius": "2rem",
                                  "max-width": "53vh"
                                },
                                attrs: { src: _vm.channel.qrcode_ref }
                              })
                            : _c(
                                "div",
                                {
                                  staticClass: "layout-padding",
                                  staticStyle: {
                                    "margin-top": "40%",
                                    padding: "0 30%"
                                  }
                                },
                                [
                                  _c("q-progress", {
                                    attrs: {
                                      indeterminate: "",
                                      color: "secondary",
                                      stripe: "",
                                      animate: "",
                                      height: "12px"
                                    }
                                  })
                                ],
                                1
                              )
                        ]
                      ),
                      _c(
                        "transition",
                        {
                          attrs: {
                            appear: "",
                            "enter-active-class":
                              "animated fadeInUp animated-d800"
                          }
                        },
                        [
                          _vm.channel.qrcode_ref
                            ? _c(
                                "p",
                                {
                                  staticClass:
                                    "text-family-brand text-weight-semibold text-center font-size-100p text-attention",
                                  staticStyle: {
                                    "word-break": "break-all",
                                    "margin-bottom": "-20px"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n          " +
                                      _vm._s(_vm.channel.shortlink) +
                                      "\n          "
                                  ),
                                  _c("q-btn", {
                                    attrs: {
                                      flat: "",
                                      round: "",
                                      icon: "ion-link"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.openURL(
                                          _vm.channel.shortlink
                                        )
                                      }
                                    }
                                  }),
                                  _c("q-btn", {
                                    attrs: {
                                      flat: "",
                                      round: "",
                                      icon: "ion-refresh"
                                    },
                                    on: { click: _vm.qrcodeCreate }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogAutomation", position: "bottom" },
              model: {
                value: _vm.dialogAutomationShow,
                callback: function($$v) {
                  _vm.dialogAutomationShow = $$v
                },
                expression: "dialogAutomationShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    { attrs: { slot: "header", inverted: "" }, slot: "header" },
                    [_c("q-toolbar-title", [_vm._v("Automation")])],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.dialogAutomationShow = false
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("DONE")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "layout-padding no-padding-top text-family-brand"
                    },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Settings")]
                          ),
                          _c(
                            "q-item",
                            { attrs: { item: "", link: "", tag: "label" } },
                            [
                              _c(
                                "q-item-side",
                                { staticClass: "text-center" },
                                [
                                  _c(
                                    "transition",
                                    {
                                      attrs: {
                                        mode: "out-in",
                                        appear: "",
                                        "enter-active-class":
                                          "animated400 bounceIn",
                                        "leave-active-class":
                                          "animated400 bounceOut"
                                      }
                                    },
                                    [
                                      _vm.automationEnabled
                                        ? _c("q-icon", {
                                            key: "automation-enabled-on",
                                            attrs: {
                                              name: "ion-flash",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                        : _c("q-icon", {
                                            key: "automation-enabled-off",
                                            attrs: {
                                              name: "ion-flash-off",
                                              color: _vm.anyDarkmode
                                                ? ""
                                                : "blue-grey-10",
                                              size: "33px"
                                            }
                                          })
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-main",
                                [
                                  _c("q-item-tile", { attrs: { label: "" } }, [
                                    _vm._v("Automated States")
                                  ]),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _vm._v(
                                        "Enable use of IoT virtual devices"
                                      )
                                    ]
                                  )
                                ],
                                1
                              ),
                              _c(
                                "q-item-side",
                                { attrs: { right: "" } },
                                [
                                  _c("q-toggle", {
                                    attrs: { dark: _vm.anyDarkmode },
                                    model: {
                                      value: _vm.automationEnabled,
                                      callback: function($$v) {
                                        _vm.automationEnabled = $$v
                                      },
                                      expression: "automationEnabled"
                                    }
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("API Parameters")]
                          ),
                          _c(
                            "q-item",
                            { attrs: { item: "" } },
                            [
                              _c(
                                "q-item-main",
                                [
                                  _c("q-item-tile", { attrs: { label: "" } }, [
                                    _vm._v("Base URI")
                                  ]),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _c("pre", [
                                        _c("strong", [_vm._v("GET")]),
                                        _vm._v(" https://api.mywings.app/")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            { attrs: { item: "" } },
                            [
                              _c(
                                "q-item-main",
                                [
                                  _c("q-item-tile", { attrs: { label: "" } }, [
                                    _vm._v("Channel Path")
                                  ]),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _c("pre", [
                                        _vm._v(
                                          "/nservice/" +
                                            _vm._s(_vm.product.data.uri)
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            { attrs: { item: "" } },
                            [
                              _c(
                                "q-item-main",
                                [
                                  _c("q-item-tile", { attrs: { label: "" } }, [
                                    _vm._v("Set Device Status")
                                  ]),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _c("pre", [
                                        _vm._v("/set/"),
                                        _c("strong", [
                                          _vm._v("DEVICE:VIRTUAL_COMPONENT")
                                        ])
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            { attrs: { item: "" } },
                            [
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    { attrs: { label: "" } },
                                    [
                                      _vm._v("Session Token "),
                                      _c("q-chip", { attrs: { dense: "" } }, [
                                        _vm._v("Dynamic")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _c("pre", [
                                        _vm._v(
                                          "io_session_token=" +
                                            _vm._s(
                                              _vm.$store.state.app.io
                                                .io_session_token
                                            )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c(
                            "q-item",
                            { attrs: { item: "" } },
                            [
                              _c(
                                "q-item-main",
                                [
                                  _c(
                                    "q-item-tile",
                                    { attrs: { label: "" } },
                                    [
                                      _vm._v("Access Token "),
                                      _c("q-chip", { attrs: { dense: "" } }, [
                                        _vm._v("Dynamic")
                                      ])
                                    ],
                                    1
                                  ),
                                  _c(
                                    "q-item-tile",
                                    { attrs: { sublabel: "" } },
                                    [
                                      _c("pre", [
                                        _vm._v(
                                          "io_access_token=" +
                                            _vm._s(
                                              _vm.$store.state.app.io
                                                .io_access_token
                                            )
                                        )
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-list",
                        {
                          staticClass:
                            "card text-system-brand text-weight-medium full-width on-top-lg",
                          attrs: { "no-border": "", link: "" }
                        },
                        [
                          _c(
                            "q-list-header",
                            {
                              staticClass:
                                "text-weight-bold text-grey text-left"
                            },
                            [_vm._v("Virtual Devices")]
                          ),
                          _vm._l(_vm.autogroups[0], function(ag) {
                            return _c(
                              "q-item",
                              { key: ag.id, attrs: { item: "" } },
                              [
                                _c(
                                  "q-item-side",
                                  { staticClass: "text-center" },
                                  [
                                    _c("q-icon", {
                                      attrs: {
                                        name: "ion-cube",
                                        color: _vm.anyDarkmode
                                          ? ""
                                          : "blue-grey-10",
                                        size: "33px"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "q-item-main",
                                  [
                                    _c(
                                      "q-item-tile",
                                      { attrs: { label: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(ag.name) +
                                            " (" +
                                            _vm._s(ag.id) +
                                            ")"
                                        )
                                      ]
                                    ),
                                    _c(
                                      "q-item-tile",
                                      { attrs: { sublabel: "" } },
                                      [
                                        _vm._v(
                                          _vm._s(ag.type) +
                                            " - " +
                                            _vm._s(ag.description)
                                        )
                                      ]
                                    ),
                                    _c(
                                      "q-item-tile",
                                      { attrs: { sublabel: "" } },
                                      _vm._l(Object.keys(ag.vdevices), function(
                                        vd
                                      ) {
                                        return _c(
                                          "q-chip",
                                          {
                                            key: vd,
                                            staticClass: "margin-xs-r",
                                            attrs: { dense: "" }
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(vd) +
                                                " → " +
                                                _vm._s(ag.vdevices[vd].link)
                                            )
                                          ]
                                        )
                                      }),
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          })
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "appLayer dialog-item",
              attrs: { id: "dialogShare", position: "bottom" },
              model: {
                value: _vm.dialogClaimShow,
                callback: function($$v) {
                  _vm.dialogClaimShow = $$v
                },
                expression: "dialogClaimShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                {
                  staticStyle: {
                    "background-image":
                      "url(/statics/_demo/checkmark.fill.seal_wing.svg)"
                  }
                },
                [
                  _c(
                    "q-toolbar",
                    { attrs: { slot: "header", inverted: "" }, slot: "header" },
                    [
                      _c("q-toolbar-title", [
                        _vm._v(_vm._s(_vm.$t("CLAIM.BUSINESS")))
                      ])
                    ],
                    1
                  ),
                  _c(
                    "q-toolbar",
                    {
                      staticClass: "toolbar-overscroll-shadow",
                      attrs: { slot: "header", inverted: "" },
                      slot: "header"
                    },
                    [
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    loading: _vm.dialogClaimVerifying,
                                    disabled:
                                      !_vm.product.data.business.website ||
                                      _vm.product.data.verified,
                                    color: "empower-light",
                                    "text-color": "empower",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.verify($event)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("VERIFY")) +
                                      "\n              "
                                  ),
                                  _c("q-spinner-puff", {
                                    attrs: { slot: "loading", size: "20px" },
                                    slot: "loading"
                                  })
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "q-card",
                        {
                          staticClass:
                            "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                        },
                        [
                          _c(
                            "q-card-main",
                            {
                              staticClass: "column justify-center full-height"
                            },
                            [
                              _c(
                                "q-btn",
                                {
                                  staticClass:
                                    "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                  attrs: {
                                    color: "primary-light",
                                    "text-color": "primary",
                                    rounded: ""
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      _vm.dialogClaimShow = false
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("DONE")) +
                                      "\n            "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "layout-padding no-padding-top text-family-brand"
                    },
                    [
                      _c("q-scroll-observable", {
                        on: { scroll: _vm.toolbarShadowOnOverscroll }
                      }),
                      _c(
                        "div",
                        {
                          staticClass:
                            "verified text-family-brand flex justify-center no-padding-top capitalize on-bottom-lg"
                        },
                        [
                          _vm.product.verified
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "font-size-140p wings-congratulations moveup"
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "text-weight-medium",
                                      class: {
                                        "text-secondary": !_vm.product.verified,
                                        "text-primary": _vm.product.verified
                                      },
                                      staticStyle: { "margin-right": "4px" }
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            "VERIFIED_" +
                                              _vm.product.verified_status
                                          )
                                        )
                                      )
                                    ]
                                  ),
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "text-subinfo-l text-weight-medium"
                                    },
                                    [
                                      _vm._v(
                                        "on " +
                                          _vm._s(
                                            _vm._f("tformat")(
                                              _vm.product.verified_timestamp
                                            )
                                          )
                                      )
                                    ]
                                  )
                                ]
                              )
                            : _c("div", [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "text-wing text-weight-semibold font-size-140p"
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("VERIFIED_NOT")) +
                                        "\n            "
                                    )
                                  ]
                                )
                              ])
                        ]
                      ),
                      !_vm.product.verified
                        ? [
                            _c("hr", { staticClass: "pill" }),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "q-title layout-padding no-padding-top text-subinfo slideInDown"
                              },
                              [
                                _vm._v(
                                  "\n          Claim your business by adding a text tag to your official website.\n        "
                                )
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "q-title layout-padding text-subinfo slideInDown no-padding-top no-margin text-center"
                              },
                              [
                                _c("img", {
                                  staticClass: "margin-auto-lr block opacity-6",
                                  class: {
                                    "filter-invert-100": _vm.anyDarkmode
                                  },
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px"
                                  },
                                  attrs: {
                                    src: "/statics/_demo/1.circle.fill.svg"
                                  }
                                }),
                                _c("strong", [
                                  _vm._v("Your identification tag")
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-weight-semibold code-container on-bottom"
                              },
                              [
                                _vm._v(
                                  '\n          <meta name="letsbutterfly-wings-' +
                                    _vm._s(_vm.product.data.uri.split("-")[0]) +
                                    '" content="' +
                                    _vm._s(_vm.product.data.uri.split("-")[1]) +
                                    '">\n        '
                                )
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "q-title layout-padding text-subinfo slideInDown no-margin text-center"
                              },
                              [
                                _c("img", {
                                  staticClass:
                                    "margin-auto-lr block opacity-6 on-left-md",
                                  class: {
                                    "filter-invert-100": _vm.anyDarkmode
                                  },
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px"
                                  },
                                  attrs: {
                                    src: "/statics/_demo/2.circle.fill.svg"
                                  }
                                }),
                                _c("strong", [_vm._v("Your website")])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-weight-semibold code-container on-bottom"
                              },
                              [
                                _vm.product.data.business.website
                                  ? [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.product.data.business.website
                                          ) +
                                          "\n          "
                                      )
                                    ]
                                  : [
                                      _c("q-icon", {
                                        staticClass: "text-warning on-left",
                                        attrs: {
                                          name: "ion-warning",
                                          size: "22px"
                                        }
                                      }),
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.$t("NOT_FOUND")) +
                                          "\n            "
                                      ),
                                      _c(
                                        "p",
                                        {
                                          staticClass:
                                            "no-margin on-top-default text-protect"
                                        },
                                        [
                                          _vm._v(
                                            "\n              Update your Google Business account with your website and check again.\n            "
                                          )
                                        ]
                                      )
                                    ]
                              ],
                              2
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "q-title layout-padding text-subinfo slideInDown no-margin text-center"
                              },
                              [
                                _c("img", {
                                  staticClass:
                                    "margin-auto-lr block opacity-6 on-left-md",
                                  class: {
                                    "filter-invert-100": _vm.anyDarkmode
                                  },
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px"
                                  },
                                  attrs: {
                                    src: "/statics/_demo/3.circle.fill.svg"
                                  }
                                }),
                                _c("strong", [
                                  _vm._v("Add ID to website's <head> tag")
                                ])
                              ]
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "text-weight-semibold code-container on-bottom text-faded"
                              },
                              [
                                _vm._v("\n          <html>\n          "),
                                _c("br"),
                                _vm._v("<head>\n          "),
                                _c("br"),
                                _vm._v(" ...\n          "),
                                _c("br"),
                                _c("span", { staticClass: "text-educate" }, [
                                  _vm._v(
                                    '<meta name="letsbutterfly-wings-' +
                                      _vm._s(
                                        _vm.product.data.uri.split("-")[0]
                                      ) +
                                      '" content="' +
                                      _vm._s(
                                        _vm.product.data.uri.split("-")[1]
                                      ) +
                                      '">'
                                  )
                                ]),
                                _c("br"),
                                _vm._v("</head>\n          "),
                                _c("br"),
                                _vm._v(" ...\n        ")
                              ]
                            ),
                            _c(
                              "p",
                              {
                                staticClass:
                                  "q-title layout-padding text-subinfo slideInDown no-margin text-center"
                              },
                              [
                                _c("img", {
                                  staticClass:
                                    "margin-auto-lr block opacity-6 on-left-md",
                                  class: {
                                    "filter-invert-100": _vm.anyDarkmode
                                  },
                                  staticStyle: {
                                    width: "40px",
                                    height: "40px"
                                  },
                                  attrs: {
                                    src: "/statics/_demo/4.circle.fill.svg"
                                  }
                                }),
                                _c("strong", [_vm._v("Tap VERIFY")])
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "q-modal",
            {
              staticClass: "dialogItem appLayer",
              attrs: { id: "dialogItem", position: "bottom" },
              model: {
                value: _vm.dialogPerspectiveShow,
                callback: function($$v) {
                  _vm.dialogPerspectiveShow = $$v
                },
                expression: "dialogPerspectiveShow"
              }
            },
            [
              this.dialogPerspectiveItem
                ? _c(
                    "q-modal-layout",
                    {
                      style:
                        "background-image: url(/statics/_demo/" +
                        this.dialogPerspectiveItem.icon +
                        ".svg);"
                    },
                    [
                      _c(
                        "q-toolbar",
                        {
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [
                          _c("q-toolbar-title", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  (
                                    this.dialogPerspectiveItem.id + ".LABEL"
                                  ).toUpperCase()
                                )
                              )
                            )
                          ])
                        ],
                        1
                      ),
                      _c(
                        "q-toolbar",
                        {
                          staticClass: "toolbar-overscroll-shadow",
                          attrs: { slot: "header", inverted: "" },
                          slot: "header"
                        },
                        [
                          _c(
                            "q-card",
                            {
                              staticClass:
                                "q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top"
                            },
                            [
                              _c(
                                "q-card-main",
                                {
                                  staticClass:
                                    "column justify-center full-height"
                                },
                                [
                                  _c(
                                    "q-btn",
                                    {
                                      staticClass:
                                        "margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase",
                                      attrs: {
                                        color: "primary-light",
                                        "text-color": "primary",
                                        rounded: ""
                                      },
                                      nativeOn: {
                                        click: function($event) {
                                          _vm.dialogPerspectiveShow = false
                                        }
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n              " +
                                          _vm._s(_vm.$t("DONE")) +
                                          "\n            "
                                      )
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "layout-padding no-padding-top" },
                        [
                          _c("q-scroll-observable", {
                            on: { scroll: _vm.toolbarShadowOnOverscroll }
                          }),
                          _c(
                            "q-list",
                            {
                              staticClass: "text-family-brand",
                              attrs: {
                                "no-border": "",
                                link: "",
                                dark: _vm.anyDarkmode
                              }
                            },
                            [
                              _vm.dialogPerspectiveItem.indicates &&
                              _vm.dialogPerspectiveItem.indicates.aux
                                ? _c(
                                    "p",
                                    {
                                      staticClass:
                                        "text-family-brand text-weight-semibold text-center font-size-160p",
                                      class: {
                                        lowercase: !_vm.dialogPerspectiveItem.indicates.aux()
                                          .unformat,
                                        "text-educate":
                                          _vm.dialogPerspectiveItem.indicates.aux()
                                            .state === 1,
                                        "text-attention":
                                          _vm.dialogPerspectiveItem.indicates.aux()
                                            .state !== 1
                                      },
                                      style: {
                                        "word-break": _vm.dialogPerspectiveItem.indicates.aux()
                                          .unformat
                                          ? "normal"
                                          : "break-all"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.dialogPerspectiveItem.indicates.aux()
                                              .text ||
                                              _vm.dialogPerspectiveItem.indicates.aux()
                                                .value
                                          ) +
                                          "\n            "
                                      ),
                                      _vm.dialogPerspectiveItem.type === "link"
                                        ? _c("q-btn", {
                                            attrs: {
                                              flat: "",
                                              round: "",
                                              icon: "ion-link"
                                            },
                                            on: {
                                              click: function($event) {
                                                _vm.openURL(
                                                  _vm.dialogPerspectiveItem.indicates.aux()
                                                    .value ||
                                                    _vm.dialogPerspectiveItem.indicates.aux()
                                                      .text
                                                )
                                              }
                                            }
                                          })
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "q-title layout-padding no-padding-top text-subinfo slideInDown",
                                  staticStyle: { "padding-bottom": "20px" }
                                },
                                [
                                  _vm._v(
                                    _vm._s(this.dialogPerspectiveItem.label)
                                  )
                                ]
                              ),
                              _vm._l(
                                this.dialogPerspectiveItem.value.options,
                                function(option, ix) {
                                  return _c(
                                    "q-item",
                                    {
                                      key: [option, ix].join("-"),
                                      attrs: {
                                        tag: "label",
                                        disabled: !_vm.checkOptionDependency(
                                          option
                                        )
                                      }
                                    },
                                    [
                                      _c(
                                        "q-item-side",
                                        [
                                          _c("q-radio", {
                                            attrs: {
                                              disable: !_vm.checkOptionDependency(
                                                option
                                              ),
                                              val: ix,
                                              darkmode: _vm.anyDarkmode
                                            },
                                            on: {
                                              input: function($event) {
                                                _vm.dialogPerspectiveItem.signal.update(
                                                  ix
                                                )
                                                _vm.dialogPerspectiveShow = false
                                              }
                                            },
                                            model: {
                                              value:
                                                _vm.dialogPerspectiveItem.value
                                                  .option,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.dialogPerspectiveItem
                                                    .value,
                                                  "option",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "dialogPerspectiveItem.value.option"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "q-item-main",
                                        { staticClass: "text-family-brand" },
                                        [
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass:
                                                "font-size-160p text-weight-semibold",
                                              attrs: { label: "" }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t(option.label))
                                              )
                                            ]
                                          ),
                                          _c(
                                            "q-item-tile",
                                            {
                                              staticClass: "text-weight-medium",
                                              staticStyle: {
                                                "font-size": "22px"
                                              },
                                              attrs: {
                                                sublabel: "",
                                                lines: "3"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(option.sublabel)
                                                  ) +
                                                  "\n              "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                }
                              )
                            ],
                            2
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
          _c(
            "q-modal",
            {
              attrs: {
                minimized: "",
                "no-esc-dismiss": "",
                "no-backdrop-dismiss": ""
              },
              model: {
                value: _vm.dialogRemovingShow,
                callback: function($$v) {
                  _vm.dialogRemovingShow = $$v
                },
                expression: "dialogRemovingShow"
              }
            },
            [
              _c(
                "q-modal-layout",
                [
                  _c(
                    "q-toolbar",
                    { attrs: { slot: "header", inverted: "" }, slot: "header" },
                    [
                      _c("q-toolbar-title", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(_vm.getEcosystemLabel("DELETE.DELETING"))
                          )
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "layout-padding" },
                    [
                      _c("q-progress", {
                        attrs: {
                          indeterminate: "",
                          color: "secondary",
                          stripe: "",
                          animate: "",
                          height: "12px"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("l-footer", { attrs: { simple: "" } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "full-width title-group text-family-brand text-weight-bolder text-center uppercase"
      },
      [
        _c("img", {
          staticClass:
            "brighten-50 dark-img-invert-100 vertical-middle animate animated-cinfinite animate-bounce animated-d4000 opacity-7",
          staticStyle: { height: "80px" },
          attrs: { src: "/statics/_demo/arrow.down.svg" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticClass:
          "full-width title-group text-family-brand text-weight-bolder text-center uppercase"
      },
      [
        _c("img", {
          staticClass:
            "brighten-50 dark-img-invert-100 vertical-middle animated-cinfinite animate-bounce animated-d4000 opacity-7",
          staticStyle: { height: "80px", "animation-delay": "1s" },
          attrs: { src: "/statics/_demo/arrow.up.svg" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }