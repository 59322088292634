export default {
  DFID: {}, // computed
  DGID: {}, // computed
  autogroups: (_this) => [{
    'vx3d00': {
      id: 'VX3D00',
      type: 'VX3D',
      name: 'Volumetric 3D IoT Sensor',
      description: 'Measures volumetric content',
      vdevices: {
        'vx3d00:vd0': {
          link: 'avocados',
          datagroup: 5,
          datafield: 13
        },
        'vx3d00:vd2': {
          link: 'honey',
          datagroup: 5,
          datafield: 15
        }
      }
    }
  }],
  datagroups: (_this) => [{
    // services
    id: 'services',
    datafields: [{
      // services > online
      id: 'online',
      icon: 'dot',
      renderInList: false,
      value: {
        option: 0,
        options: [{
          label: _this.$t('OFF')
        }, {
          label: _this.$t('ON')
        }]
      },
      indicates: {
        indicator: () => {
          return _this.product.data.channel.online
        },
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            text: [_this.channel.presense_count, _this.$t('ONLINE.LABEL')].join(' ')
          }
        }
      }
    }, {
      // services > orders
      id: 'orders',
      icon: 'cart.fill',
      renderInList: false,
      value: {
        option: 0,
        options: [{
          label: _this.$t('ORDERS.ACCEPTING')
        }, {
          label: _this.$t('ORDERS.NOT_ACCEPTING')
        }]
      }
    }, {
      // services > wifi
      id: 'wifi',
      icon: 'station.waves',
      value: {
        option: 1,
        options: [{
          label: _this.$t('OFF')
        }, {
          label: _this.$t('ON')
        }]
      }
    }, {
      // services > kitchen_training
      id: 'kitchen_training',
      icon: 'station.wait',
      valueType: Boolean
    }, {
      // services > barista_training
      id: 'barista_training',
      icon: 'station.wait',
      valueType: Boolean
    }, {
      // services > kitchen_staff
      id: 'kitchen_staff',
      icon: 'station.wait',
      renderInList: false,
      notifyLabel: true,
      label: _this.$t('KITCHEN_STAFF.D'),
      value: {
        option: 2,
        options: [{
          label: 'None'
        }, {
          label: '1'
        }, {
          label: '2'
        }, {
          label: '3'
        }, {
          label: '4'
        }]
      }
    }, {
      // services > barista_staff
      id: 'barista_staff',
      icon: 'station.wait',
      renderInList: false,
      notifyLabel: true,
      label: _this.$t('BARISTA_STAFF.D'),
      value: {
        option: 2,
        options: [{
          label: 'None'
        }, {
          label: '1'
        }, {
          label: '2'
        }, {
          label: '3'
        }, {
          label: '4'
        }]
      }
    }, {
      // services > kitchen_wait_time
      id: 'kitchen_wait_time',
      icon: 'station.wait',
      renderInList: false,
      notifyLabel: true,
      label: _this.$t('KITCHEN_WAIT_TIME.D'),
      value: {
        option: 0,
        options: [{
          label: _this.$tc('MINUTES_VAL', 0, { count: 0 }),
          label_sh: _this.$tc('MINUTES_VAL_SH', 0, { count: 0 })
        }, {
          label: _this.$tc('MINUTES_VAL', 5, { count: 5 }),
          label_sh: _this.$tc('MINUTES_VAL_SH', 5, { count: 5 })
        }, {
          label: _this.$tc('MINUTES_VAL', 10, { count: 10 }),
          label_sh: _this.$tc('MINUTES_VAL_SH', 10, { count: 10 })
        }, {
          label: _this.$tc('MINUTES_VAL', 15, { count: 15 }),
          label_sh: _this.$tc('MINUTES_VAL_SH', 15, { count: 15 })
        }, {
          label: _this.$tc('MINUTES_VAL', 25, { count: 25 }),
          label_sh: _this.$tc('MINUTES_VAL_SH', 25, { count: 25 })
        }, {
          label: _this.$tc('MINUTES_ATLEAST_VAL', 30, { count: 30 }),
          label_sh: _this.$tc('MINUTES_ATLEAST_VAL_SH', 30, { count: 30 })
        }]
      }
    }, {
      // services > barista_wait_time
      id: 'barista_wait_time',
      renderInList: false,
      notifyLabel: true,
      label: _this.$t('BARISTA_WAIT_TIME.D'),
      icon: 'station.wait',
      value: {
        option: 0,
        options: [{
          label: _this.$tc('MINUTES_VAL', 0, { count: 0 }),
          label_sh: _this.$tc('MINUTES_VAL_SH', 0, { count: 0 })
        }, {
          label: _this.$tc('MINUTES_VAL', 5, { count: 5 }),
          label_sh: _this.$tc('MINUTES_VAL_SH', 5, { count: 5 })
        }, {
          label: _this.$tc('MINUTES_VAL', 10, { count: 10 }),
          label_sh: _this.$tc('MINUTES_VAL_SH', 10, { count: 10 })
        }, {
          label: _this.$tc('MINUTES_VAL', 15, { count: 15 }),
          label_sh: _this.$tc('MINUTES_VAL_SH', 15, { count: 15 })
        }, {
          label: _this.$tc('MINUTES_VAL', 25, { count: 25 }),
          label_sh: _this.$tc('MINUTES_VAL_SH', 25, { count: 25 })
        }, {
          label: _this.$tc('MINUTES_ATLEAST_VAL', 30, { count: 30 }),
          label_sh: _this.$tc('MINUTES_ATLEAST_VAL_SH', 30, { count: 30 })
        }]
      }
    // }, {
    //   id: 'availability',
    //   icon: 'text.badge.checkmark',
    //   value: {
    //     option: 0,
    //     options: [{
    //       label: _this.$t('AVAILABILITY.FULL')
    //     }, {
    //       label: _this.$t('AVAILABILITY.PARTIAL')
    //     }]
    //   }
    // }, {
    //   id: 'offers',
    //   icon: 'gift.fill',
    //   valueType: Boolean
    }, {
      id: 'allowed_capacity',
      notifyLabel: true,
      icon: 'person.crop.circle.fill.badge.exclam',
      value: {
        option: 5,
        options: [{
          label: '1'
        }, {
          label: '2'
        }, {
          label: '3'
        }, {
          label: '4'
        }, {
          label: '5'
        }, {
          label: '6-10'
        }, {
          label: '10-50'
        }, {
          label: '50-100'
        }, {
          label: '100+'
        }]
      }
    }]
  }, {
    // healsafetyth
    id: 'safety',
    datafields: [{
      // safety > masks
      id: 'masks',
      icon: 'mask',
      valueType: Boolean
    }, {
      // safety > sanatizers
      id: 'hand_sanatizers',
      icon: 'hand',
      valueType: Boolean
    }, {
      // safety > accessibility
      id: 'accessibility',
      icon: 'handicap',
      valueType: Boolean
    }]
  }, {
    // homes
    id: 'homes',
    types: ['real_estate_agency'],
    datafields: [{
      // homes > amenities
      id: 'amenities',
      icon: 'printer.fill',
      valueType: Boolean
    }, {
      // homes > available_properties
      id: 'available_properties',
      icon: 'bed.double.fill',
      valueType: Boolean
    }]
  }, {
    // department_store
    id: 'department_store',
    types: ['department_store', 'supermarket', 'grocery_or_supermarket'],
    datafields: [{
      // department_store > sanatizers
      id: 'sanatizers',
      icon: 'hand',
      valueType: Boolean
    }]
  }, {
    // allergens
    id: 'allergens',
    types: ['restaurant', 'food'],
    datafields: [{
      // allergens > food_vegan
      id: 'food_vegan',
      icon: 'food_vegan',
      valueType: Boolean
    }, {
      // allergens > food_gluten
      id: 'food_gluten',
      icon: 'food_gluten2',
      valueType: Boolean
    }, {
      // allergens > food_dairy
      id: 'food_dairy',
      icon: 'food_dairy',
      valueType: Boolean
    }]
  }, {
    // foods
    id: 'foods',
    types: ['restaurant', 'food'],
    datafields: [{
      // foods > inventory
      id: 'inventory',
      icon: 'gauge',
      value: {
        option: 0,
        options: [{
          label: _this.$t('INVENTORY.FULL')
        }, {
          label: _this.$t('INVENTORY.PARTIAL')
        }, {
          label: _this.$t('INVENTORY.LOW')
        }]
      }
    }, {
      id: 'burgers',
      icon: 'burger',
      valueType: Boolean
    }, {
      id: 'subs',
      icon: 'sub',
      valueType: Boolean
    }, {
      id: 'wraps',
      icon: 'wrap',
      valueType: Boolean
    }, {
      id: 'bowls',
      icon: 'bowl',
      valueType: Boolean
    }, {
      id: 'salad',
      icon: 'salad',
      valueType: Boolean
    }, {
      id: 'smoothies',
      icon: 'smoothie',
      valueType: Boolean
    }, {
      id: 'icecream',
      icon: 'icecream',
      valueType: Boolean
    }, {
      id: 'soups',
      icon: 'soup',
      valueType: Boolean
    }, {
      id: 'coffee',
      icon: 'coffee',
      valueType: Boolean
    }, {
      id: 'tea',
      icon: 'tea',
      valueType: Boolean
    }, {
      id: 'wine',
      icon: 'wine',
      valueType: Boolean
    }, {
      id: 'beer',
      icon: 'beer',
      valueType: Boolean
    }, {
      id: 'avocados',
      icon: 'avocados',
      valueType: Boolean,
      automated: true
    }, {
      id: 'eggs',
      icon: 'eggs',
      valueType: Boolean
      // automated: true
    }, {
      id: 'honey',
      icon: 'persona.ingredients.honey',
      valueType: Boolean,
      automated: true
    }, {
      id: 'spinach',
      icon: 'persona.ingredients.spinach',
      valueType: Boolean
    }]
  }, {
    // auto
    id: 'auto',
    types: ['car_repair'],
    datafields: [{
      // auto > bays
      id: 'bays',
      icon: 'hammer.fill',
      value: {
        option: 0,
        options: [{
          label: _this.$t('ALL')
        }, {
          label: _this.$t('NONE')
        }, {
          label: _this.$tc('BAYS.COUNT', 1, { count: 1 })
        }, {
          label: _this.$tc('BAYS.COUNT', 2, { count: 2 })
        }, {
          label: _this.$tc('BAYS.COUNT', 3, { count: 3 })
        }, {
          label: _this.$tc('BAYS.COUNT', 4, { count: 4 })
        }, {
          label: _this.$tc('BAYS.COUNT', 5, { count: 5 })
        }, {
          label: _this.$tc('BAYS.COUNT', 6, { count: 6 })
        }, {
          label: _this.$tc('BAYS.COUNT', 7, { count: 7 })
        }, {
          label: _this.$tc('BAYS.COUNT', 8, { count: 8 })
        }, {
          label: _this.$tc('BAYS.COUNT', 9, { count: 9 })
        }, {
          label: _this.$tc('BAYS.COUNT', 10, { count: 10 })
        }]
      }
    }]
  }, {
    // animals
    id: 'animals',
    types: ['veterinary_care'],
    datafields: [{
      // animals > telehealth
      id: 'telehealth',
      icon: 'viewfinder',
      valueType: Boolean
    }]
  }, {
    // accepts
    id: 'accepts',
    datafields: [{
      // accepts > cash
      id: 'cash',
      icon: 'centsign.circle.fill',
      valueType: Boolean
    }, {
      // accepts > creditcard
      id: 'creditcard',
      icon: 'creditcard.fill',
      valueType: Boolean
    }, {
      // accepts > apple_pay
      id: 'applepay',
      icon: 'apple',
      valueType: Boolean
    }, {
      // accepts > crypto
      id: 'crypto',
      icon: 'bitcoinsign.circle.fill',
      valueType: Boolean
    }]
  }, {
    // orderby
    id: 'orderby',
    actions: {
      add_ignore: (datagroup) => {
        console.log(datagroup)
      }
    },
    datafields: [{
      // order-by > phone
      id: 'phone',
      icon: 'smartphone',
      type: 'phone',
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            text: _this.cleanURL(_this.product.data.business.phone)
          }
        }
      }
    }, {
      // order-by > website
      id: 'website',
      icon: 'link',
      type: 'link',
      valueType: Boolean,
      indicates: {
        requiresValue: true,
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: _this.product.data.business.website,
            text: _this.cleanURL(_this.product.data.business.website)
          }
        }
      }
    }, {
      // orderby > drivethru
      id: 'drivethru_ordering',
      icon: 'car.fill',
      notTypes: ['electronics_store'],
      valueType: Boolean
    }, {
      // order-by > doordash
      id: 'doordash',
      icon: 'link',
      type: 'link',
      types: ['restaurant', 'food'],
      archive: false,
      brand: true,
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: 'https://www.doordash.com',
            text: 'doordash.com'
          }
        }
      }
    }, {
      // order-by > grubhub
      id: 'grubhub',
      icon: 'link',
      type: 'link',
      types: ['restaurant', 'food'],
      archive: false,
      brand: true,
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: 'https://www.grubhub.com',
            text: 'grubhub.com'
          }
        }
      }
    }, {
      // order-by > ubereats
      id: 'ubereats',
      icon: 'link',
      type: 'link',
      types: ['restaurant', 'food'],
      archive: false,
      brand: true,
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: 'https://www.ubereats.com',
            text: 'ubereats.com'
          }
        }
      }
    }, {
      // order-by > postmates
      id: 'postmates',
      icon: 'link',
      type: 'link',
      types: ['restaurant', 'food'],
      archive: false,
      brand: true,
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: 'https://www.postmates.com',
            text: 'postmates.com'
          }
        }
      }
    }]
  }, {
    // pickup
    id: 'pickup',
    datafields: [{
      id: 'parking',
      icon: 'p.circle.fill',
      valueType: Boolean
    }, {
      id: 'drivethru',
      icon: 'car.fill',
      valueType: Boolean
    }, {
      id: 'walkins',
      icon: 'bubble.left.and.bubble.right.fill',
      valueType: Boolean
    }, {
      id: 'curbside',
      icon: 'bag.fill',
      valueType: Boolean
    }]
  }, {
    // business
    id: 'business',
    renderInList: false,
    actions: {
      remove: (datagroup) => {
        _this.remove()
      }
    },
    datafields: [{
      id: 'description',
      icon: 'info',
      valueType: Boolean,
      indicates: {
        aux: () => {
          return {
            state: _this.product.data.channel.online ? 1 : 0,
            value: _this.product.data.business.metas ? _this.product.data.business.metas.description : '',
            unformat: true
          }
        }
      }
    }, {
      id: 'cta',
      icon: 'cta',
      label: _this.$t('CTA.D'),
      value: {
        option: 0,
        options: [{
          label: '--',
          sublabel: 'Do not display.'
        }, {
          label: 'share channel'
        }, {
          label: 'call business',
          dependsOn: 'phone'
        }, {
          label: 'order online',
          dependsOn: 'website'
        }, {
          label: 'order using doordash',
          dependsOn: 'doordash'
        }, {
          label: 'order using grubhub',
          dependsOn: 'grubhub'
        }, {
          label: 'order using ubereats',
          dependsOn: 'ubereats'
        }, {
          label: 'order using postmates',
          dependsOn: 'postmates'
        }]
      }
    }]
  }]
}
