<template>
  <div v-if="ready">
    <!-- HEADER -->
    <q-layout-header id="productsEditHeader">
      <q-toolbar inverted>
        <q-toolbar-title>
           <div class="text-center cursor-pointer" style="padding-top: 13px" @click="processCardSelection('services-0-online-0')">
            <q-spinner-puff v-show="product.data.channel.online" color="gold" size="3em" class="absolute" style="z-index: 1; top: 0px; left: calc(50% - 1.5em + 0px)"/>
            <img :class="{ 'pulse': product.data.channel.online }" src="/statics/icons/icon-gold.svg" width="66" class="relative z-top"/>
            <span class="title-value text-family-brand text-weight-regular text-gold block">
              <sup class="block">&nbsp;</sup>
              <span class="text-subinfo text-weight-bold capitalize">
                <span v-if="product.data.channel.online === 1">{{ $t('LIVE') }}</span><span v-else>{{ $t('OFFLINE.LABEL') }}</span>
              </span>
            </span>
          </div>
          <span slot="subtitle" class="text-weight-medium block" style="margin-top:10px">
            <span class="block title-subject text-black no-text-transform overflow-hidden">
              {{ product.data.business.name }}
            </span>
            <span class="block text-weight-semibold text-subinfo uppercase overflow-hidden" v-html="product.data.business.address.html"></span>
          </span>
        </q-toolbar-title>
        <q-btn round size="lg" text-color="primary" class="absolute z-top" @click="exit">
          <img src="/statics/_demo/arrow_primary.svg" width="22">
        </q-btn>
        <q-btn round size="lg" text-color="primary" class="absolute z-top" @click="qrcode">
          <img src="/statics/_demo/qrcode_primary.svg" width="22">
        </q-btn>
      </q-toolbar>
    </q-layout-header>
    <!-- CONTENT -->
    <div ref="productsEditContent" id="productsEditContent" class="layout-padding no-pointer-events" style="opacity: 0; padding-top: 0">
      <q-scroll-observable @scroll="scrolled"/>

      <div class="product">

        <!-- hello, friend!
        <template v-if="product.data.channel.online === false">
          <transition appear enter-active-class="animated fadeInUp animated-d800">
            <div class="full-width title-group text-family-brand text-weight-bolder text-center uppercase on-top-xx">{{ $t('HELLO_FRIEND') }}</div>
          </transition>
          <transition appear enter-active-class="animated fadeInUp animated-d800">
            <q-card class="full-width limit-width-100p no-background no-border no-shadow text-center">
              <q-card-main class="column justify-center full-height no-padding-top">
                <div class="col-xs-auto on-top-xs">
                  <q-btn class="limit-width-420 full-width text-family-brand text-weight-semibold uppercase" color="empower-gradient" text-color="white" rounded :label="$t('SEND')" @click="processCardSelection('services-0-online-0')"/>
                </div>
              </q-card-main>
            </q-card>
          </transition>
        </template>
        -->

        <!-- channel -->
        <!-- <transition appear enter-active-class="animated fadeInUp animated-d800"> -->
          <!-- <div class="full-width title-group text-family-brand text-weight-bolder text-center uppercase on-top-xx">{{ $t('CHANNEL.LABEL_TT') }}</div> -->
        <!-- </transition> -->
        <transition appear enter-active-class="animated fadeInUp animated-d800">
          <div class="row no-wrap items-center margin-auto-lr relative-position" style="max-width: 400px; z-index: 1">
            <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
              <q-card-main class="column justify-center full-height">
                <q-btn icon="ion-share" class="q-btn-icon-keep-left margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded @click.native="share">
                  {{ $t('SHARE') }}
                </q-btn>
              </q-card-main>
            </q-card>
            <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
              <q-card-main class="column justify-center full-height">
                <q-btn class="q-btn-icon-keep-left margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded :icon="product.verified ? 'ion-checkmark-circle' : 'ion-help-circle'" :label="product.verified ? $t('CLAIM.BUSINESS_CLAIMED') : $t('CLAIM.BUSINESS')" @click.native="claim"/>
              </q-card-main>
            </q-card>
          </div>
        </transition>

        <!-- publishing debug button -->
        <div class="row no-wrap items-center margin-auto-lr relative-position" style="max-width: 400px; z-index: 1">
          <!-- <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary" text-color="white" rounded>
                Customers
              </q-btn>
            </q-card-main>
          </q-card> -->
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="protect" text-color="white" rounded @click="channelRequestsClear">
                Reset &nbsp; Queue
              </q-btn>
            </q-card-main>
          </q-card>
        </div>

        <!-- customers -->
        <div v-if="requests.length" class="col justify-between items-center margin-auto-lr datagroup-container">
          <transition appear enter-active-class="animated fadeInUp animated-d800">
            <div class="full-width title-group text-family-brand text-weight-bolder text-center uppercase">
              Customers
            </div>
          </transition>
          <div class="row justify-between" style="margin-top: -60px">
            <div class="cols" style="width:50%">
              <div class="full-width title-group text-family-brand text-weight-bolder text-center uppercase">
                <img src="/statics/_demo/arrow.down.svg" class="brighten-50 dark-img-invert-100 vertical-middle animate animated-cinfinite animate-bounce animated-d4000 opacity-7" style="height:80px">
              </div>
              <template v-for="(request, ix) of requests">
                <transition v-if="!request.status || request.status === 'in-review'" :key="`product-requests-${ix}-${request.status}`" appear enter-active-class="animated fadeInUp animated-d800">
                  <q-card inline class="overflow-hidden q-card-grouped q-card-widget" style="max-width:100%"
                  :ref="`product-card-request-${ix}-${request.status}`" v-touch-pan.noMouse="(obj) => { setCardIntent(obj, `request-${ix}-${request.status}`, () => { requestView(ix, request) }) }">
                    <q-card-title>
                      <img :src="request.item.photo" class="avatar float-left" style="width: 80px; height: 80px; border-radius: 17px; border-bottom-right-radius: 0;">
                      <div class="float-left">
                        <span class="block on-right-sm capitalize text-weight-bold font-size-120p">
                          {{ request.info.name.replace(/\_/g, ' ') }}
                        </span>
                        <span class="block on-right-sm">
                          <div class="columns flex justify-between" style="height: 48px">
                            <div class="col-1">
                              <img src="/statics/_demo/station.person.svg" height="30" class="brighten-50 dark-img-invert-50 vertical-middle on-left-sm"/>
                            </div>
                            <div class="col">
                              <em style="padding: 0; top: -7px; position: relative;">with</em><br><b class="uppercase font-size-80p" style="color: #004BA3; position: relative; top: -23px">{{ request.location }}</b>
                            </div>
                          </div>
                        </span>
                      </div>
                      <div slot="right">
                        <div style="position: relative; top: -22px">
                          <span class="block font-size-120p text-family-brand text-primary text-right text-weight-bold">
                            <span class="text-subinfo-l">#</span>{{ request.id }}
                          </span>
                          <q-chip dense class="q-chip-info on-right-xs text-family-brand">
                            {{ request.status.replace(/\_/g, ' ') }}
                          </q-chip>
                        </div>
                      </div>
                    </q-card-title>
                    <q-card-separator v-show="!$q.platform.has.touch"/>
                    <q-card-actions v-show="!$q.platform.has.touch">
                      <q-btn class="full-width" color="primary" flat rounded :label="$t('EDIT')" @click="requestView(ix, request)">
                        <q-tooltip :delay="2000" inverted>
                          {{ $t(getEcosystemLabel('EDIT.LABEL_TT')) }}
                        </q-tooltip>
                      </q-btn>
                    </q-card-actions>
                  </q-card>
                </transition>
              </template>
            </div>
            <div class="cols" style="width:50%">
              <div class="full-width title-group text-family-brand text-weight-bolder text-center uppercase">
                <img src="/statics/_demo/arrow.up.svg" class="brighten-50 dark-img-invert-100 vertical-middle animated-cinfinite animate-bounce animated-d4000 opacity-7" style="height:80px; animation-delay: 1s">
              </div>
              <template v-for="(request, ix) of requests">
                <transition v-if="request.status && request.status !== 'in-review' && request.status !== 'finalized'" :key="`product-requests-${ix}-${request.status}`" appear enter-active-class="animated fadeInUp animated-d800">
                  <q-card inline class="overflow-hidden q-card-grouped q-card-widget" style="max-width:100%"
                  :ref="`product-card-request-${ix}-${request.status}`" v-touch-pan.noMouse="(obj) => { setCardIntent(obj, `request-${ix}-${request.status}`, () => { requestView(ix, request) }) }">
                    <q-card-title>
                      <img :src="request.item.photo" class="avatar float-left" style="width: 80px; height: 80px; border-radius: 17px; border-bottom-right-radius: 0;">
                      <div class="float-left">
                        <span class="block on-right-sm capitalize text-weight-bold font-size-120p">
                          {{ request.info.name.replace(/\_/g, ' ') }}
                        </span>
                        <span class="block on-right-sm">
                          <div class="columns flex justify-between" style="height: 48px">
                          <template v-if="request.status === 'completed'">
                            <div class="col-1">
                              <img src="/statics/_demo/checkmark.circle.fill.svg" height="30" class="brighten-50 dark-img-invert-50 vertical-middle on-left-sm"/>
                            </div>
                            <div class="col">
                              <em style="padding: 0; top: -7px; position: relative;">with</em><br><b class="uppercase font-size-80p" style="color: #004BA3; position: relative; top: -23px">CUSTOMER</b>
                            </div>
                          </template>
                          <template v-else-if="request.status === 'dropping-off'">
                            <div class="col-1">
                              <img src="/statics/_demo/viewfinder.qrcode.svg" height="30" class="brighten-50 dark-img-invert-50 vertical-middle on-left-sm"/>
                            </div>
                            <div class="col">
                              <em style="padding: 0; top: -7px; position: relative;">to</em><br><b class="uppercase font-size-80p" style="color: #004BA3; position: relative; top: -23px">PATIO #1</b>
                            </div>
                          </template>
                          <template v-else>
                            <div class="col-1">
                              <img src="/statics/_demo/station.globe.svg" height="30" class="brighten-50 dark-img-invert-50 vertical-middle on-left-sm animate-spin"/>
                            </div>
                            <div class="col">
                              <em style="padding: 0; top: -7px; position: relative;">at</em><br><b class="uppercase font-size-80p" style="color: #004BA3; position: relative; top: -23px">{{ request.info.station.replace(/\_/g, ' ') }}</b>
                            </div>
                          </template>
                          </div>
                        </span>
                      </div>
                      <div slot="right">
                        <div style="position: relative; top: -22px">
                          <span class="block font-size-120p text-family-brand text-primary text-right text-weight-bold">
                            <span class="text-subinfo-l">#</span>{{ request.id }}
                          </span>
                          <q-chip dense class="on-right-xs text-family-brand" :class="{
                            'q-chip-hint': request.status === 'in-progress',
                            'q-chip-pro-fill': request.status === 'dropping-off',
                            'q-chip-educate': request.status === 'completed'
                          }">
                            {{ request.status.replace(/\_/g, ' ') }}
                          </q-chip>
                        </div>
                      </div>
                    </q-card-title>
                    <q-card-separator v-show="!$q.platform.has.touch"/>
                    <q-card-actions v-show="!$q.platform.has.touch">
                      <q-btn class="full-width" color="primary" flat rounded :label="$t('EDIT')" @click="requestView(ix, request)">
                        <q-tooltip :delay="2000" inverted>
                          {{ $t(getEcosystemLabel('EDIT.LABEL_TT')) }}
                        </q-tooltip>
                      </q-btn>
                    </q-card-actions>
                  </q-card>
                </transition>
              </template>
            </div>
          </div>
        </div>

        <!-- controls -->
        <div class="row justify-between items-center margin-auto-lr datagroup-container">
          <transition appear enter-active-class="animated fadeInUp animated-d800">
            <div class="full-width title-group text-family-brand text-weight-bolder text-center uppercase">
              Controls
            </div>
          </transition>
        </div>
        <template v-for="(datagroup, index) of datagroups">
          <div v-if="!datagroup.types || (datagroup.types && (datagroup.types.filter(el => product.data.business.types.includes(el))).length)" :key="`product-datagroup-${datagroup.id}-${index}`" class="row justify-between items-center margin-auto-lr datagroup-container">
            <transition appear enter-active-class="animated fadeInUp animated-d800">
              <div v-if="datagroup.id !== 'services'" :class="{ 'on-top-xx': index > 0 }" class="full-width title-group text-family-brand text-weight-bolder text-center uppercase">{{ $t((datagroup.id).toUpperCase() + '.LABEL_TT') }}</div>
            </transition>
            <template v-for="(datafield, ix) of datagroup.datafields">
              <transition v-if="datafield.archive !== true" :key="`product-datagroup-${datagroup.id}-${index}-${datafield.id}`" appear enter-active-class="animated fadeInUp animated-d800">
                <q-card
                  v-if="(!datafield.types || datafield.types.filter(el => product.data.business.types.includes(el)).length) && (!datafield.notTypes || !datafield.notTypes.filter(el => product.data.business.types.includes(el)).length)"
                  inline class="overflow-hidden q-card-grouped q-card-widget"
                  :data-index="[datagroup.id, index, datafield.id, ix].join('-')"
                  :ref="`product-card-` + [datagroup.id, index, datafield.id, ix].join('-')"
                  :disabled="datafield.indicates && datafield.indicates.requiresValue && !datafield.indicates.aux().value"
                  v-touch-pan.noMouse="(obj) => { setCardIntent(obj, [datagroup.id, index, datafield.id, ix].join('-'), processCardSelection) }"
                  >
                  <template v-if="datafield.indicates && datafield.indicates.indicator">
                    <q-progress v-if="datafield.indicates.indicator()" stripe indeterminate color="educate" height="4px" style="margin-bottom:-4px"/>
                    <q-progress v-else :percentage="100" stripe color="protect" height="4px" style="margin-bottom:-4px"/>
                  </template>
                  <q-progress v-else-if="datafield.automated === true && automationEnabled === true" stripe indeterminate color="educate" height="4px" style="margin-bottom:-4px"/>
                  <q-card-title>
                    <div class="opacity-9 float-left on-left-sm on-bottom-lg overflow-hidden text-center" style="width: 33px; height: 33px;">
                      <img :src="`/statics/_demo/${datafield.icon}.svg`" width="24" style="max-height: 24px" :class="{ 'filter-invert-100': anyDarkmode }">
                    </div>
                    <span class="title-label block text-primary q-caption text-family-brand text-weight-semibold uppercase">
                      {{ $t([datafield.id, 'LABEL'].join('.').toUpperCase()) }}
                      <span v-if="datafield.automated === true && automationEnabled === true" class="absolute uppercase chip-live" style="top:10px;right:10px; width:auto !important">LIVE</span>
                      <span v-else-if="datafield.automated === true && automationEnabled === false" class="absolute uppercase chip-offline" style="top:10px;right:10px; width:auto !important">OFF</span>
                    </span>
                    <span v-if="datafield.signal && datafield.signal.descriptor()" class="title-main on-top-sm block line-height-sm float-left">
                      <span v-if="datafield.indicates && datafield.indicates.requiresValue && !datafield.indicates.aux().value" class="text-value">
                        &nbsp; <q-icon name="ion-warning" class="text-warning on-left" size="22px"/> {{ $t('NOT_FOUND') }}
                      </span>
                      <span v-else>
                        <template v-if="datafield.signal.bCheck() !== null">
                          &nbsp; <q-icon :name="datafield.signal.bCheck() === false ? 'ion-close-circle' : 'ion-checkmark-circle'"
                            class="text-educate on-left" size="22px" :class="datafield.signal.bCheck() === false ? 'text-shadow-l' : 'text-educate'"/>
                        </template>
                        {{ datafield.signal.descriptor() }}
                      </span>
                    </span>
                    <span v-if="datafield.indicates && datafield.indicates.aux" slot="right" :class="datafield.indicates.aux().state === 1 ? 'text-educate' : 'text-attention'" class="text-right text-family-brand text-weight-semibold absolute-top-right on-left-lg on-top-lgr lowercase ellipsis q-card-title-aux">
                        {{ datafield.indicates.aux().text }}
                    </span>
                  </q-card-title>
                  <q-card-separator v-show="!$q.platform.has.touch"/>
                  <q-card-actions v-show="!$q.platform.has.touch">
                    <q-btn v-if="datafield.paidFeature && (!product.data.paidFeatures || !product.data.paidFeatures[datafield.paidFeature])" class="full-width" color="primary" flat rounded :label="$t('ENABLE')">
                      <q-tooltip :delay="2000" inverted>
                        {{ $t(getEcosystemLabel('ENABLE.LABEL_TT')) }}
                      </q-tooltip>
                    </q-btn>
                    <q-btn v-else class="full-width" color="primary" flat rounded :label="$t('EDIT')" @click="processCardSelection([datagroup.id, index, datafield.id, ix].join('-'))">
                      <q-tooltip :delay="2000" inverted>
                        {{ $t(getEcosystemLabel('EDIT.LABEL_TT')) }}
                      </q-tooltip>
                    </q-btn>
                  </q-card-actions>
                </q-card>
              </transition>
            </template>
            <!-- add -->
            <template v-if="datagroup.actions && datagroup.actions.add">
              <div v-if="datagroup.datafields.length" :key="`product-datagroup-${datagroup.id}-${index}-actions-break`" class="flex-break"></div>
              <transition v-key="`product-datagroup-${datagroup.id}-${index}-actions-add`" appear enter-active-class="animated fadeInUp animated-d800">
                <q-card class="full-width limit-width-100p no-background no-border no-shadow text-center">
                  <q-card-main class="column justify-center full-height no-padding-top">
                    <div class="col-xs-auto">
                      <q-btn class="limit-width-420 full-width text-family-brand text-weight-semibold" color="secondary-light" text-color="secondary" rounded :label="$t('ADD')" @click="datagroup.actions.add(datagroup)"/>
                    </div>
                  </q-card-main>
                </q-card>
              </transition>
            </template>
            <!-- remove -->
            <template v-if="datagroup.actions && datagroup.actions.remove">
              <div v-if="datagroup.datafields.length" :key="`product-datagroup-${datagroup.id}-${index}-actions-break`" class="flex-break"></div>
              <transition v-key="`product-datagroup-${datagroup.id}-${index}-actions-remove`" appear enter-active-class="animated fadeInUp animated-d800">
                <q-card class="full-width limit-width-100p no-background no-border no-shadow text-center">
                  <q-card-main class="column justify-center full-height no-padding-top">
                    <div class="col-xs-auto">
                      <q-btn class="limit-width-420 full-width text-family-brand text-weight-semibold uppercase" color="protect-gradient" text-color="white" rounded :label="$t('REMOVE')" @click="datagroup.actions.remove(datagroup)"/>
                    </div>
                  </q-card-main>
                </q-card>
              </transition>
            </template>
          </div>
        </template>

      </div>
    </div>

    <!--
      STICKY_BUTON: Automation Configuration
    -->
    <q-page-sticky position="bottom-right" :offset="[20, 20]">
      <transition appear enter-active-class="animated fadeInUp animated400" leave-active-class="animated fadeOutDown animated400">
        <q-btn size="lg" v-ripple round push color="primary" @click="automationSettings" class="shadow-24">
          <q-icon name="ion-code-working" size="44px" color="white"/>
        </q-btn>
      </transition>
    </q-page-sticky>

    <!--
      MODAL: Request View
    -->
    <q-modal id="dialogRequest" v-model="dialogRequestShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout v-if="dialogRequestIndex !== null && requests[dialogRequestIndex]">
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ requests[dialogRequestIndex].info.name.replace(/\_/g, ' ') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogRequestShow = false">
                {{ $t('DONE') }}
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="text-family-brand layout-padding no-padding-tb row justify-center items-center" style="max-width: 500px">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="shadow-l" text-color="black" rounded @click="requestStatusUpdate('in-review')">
                In-Review
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="standout" text-color="white" rounded @click="requestStatusUpdate('in-progress')">
                In-Progress
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="attention" text-color="white" rounded @click="requestStatusUpdate('dropping-off')">
                Dropping-off
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="educate" text-color="white" rounded @click="requestStatusUpdate('completed')">
                Completed
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="purple" text-color="white" rounded @click="requestStatusUpdate('finalize')">
                Finalize / Archive
              </q-btn>
            </q-card-main>
          </q-card>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Share
    -->
    <q-modal id="dialogShare" v-model="dialogShareShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout style="background-image: url(/statics/_demo/square.and.arrow.up.fill_primary.svg)">
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t('SHARE') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogShareShow = false">
                {{ $t('DONE') }}
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list class="text-family-brand" no-border link :dark="anyDarkmode">
            <!--
            <p class="text-family-brand text-weight-semibold lowercase text-center font-size-160p" style="word-break: break-all">
              {{ productFullURI }}
              <q-btn flat round icon="ion-link" @click="openURL(productFullURI)"/>
            </p>
            -->
            <p class="q-title layout-padding no-padding-top text-subinfo slideInDown" style="padding-bottom:20px">
              Share this channel using any of the following methods and platforms for everyone to view.
            </p>
            <q-item v-if="shareSheetSupport()" item @click.native="shareSheet">
              <q-item-main :label="$t('SHARESHEET.LABEL')" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/rectangle.stack.fill.svg" style="height:33px" :class="{ 'filter-invert-80': anyDarkmode }">
              </q-item-side>
            </q-item>
            <q-item item @click.native="openURL(productFullURI)">
              <q-item-main :label="$t('WEBLINK.LABEL')" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/square.and.arrow.up.fill_primary.svg" style="height:33px">
              </q-item-side>
            </q-item>
            <q-item item @click.native="qrcode">
              <q-item-main :label="$t('QRCODE.LABEL')" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/qrcode_primary.svg" style="height:33px">
              </q-item-side>
            </q-item>
            <!--
            <q-item item @click.native="openURL('https://www.facebook.com/dialog/share?display=popup&app_id=180012859210301&text=' + productFullURI)">
              <q-item-main label="facebook" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/logo.facebook.svg" style="height:33px">
              </q-item-side>
            </q-item>
            <q-item item @click.native="openURL('https://twitter.com/intent/tweet?text=🦋' + $t('SHARELINKS.TWITTER') + ' ' + productFullURI)">
              <q-item-main label="twitter" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/logo.twitter.svg" style="height:33px">
              </q-item-side>
            </q-item>
            <q-item item @click.native="openURL('https://linkedin.com/shareArticle?title=🦋' + $t('SHARELINKS.TWITTER') + '&utl=' + productFullURI)">
              <q-item-main label="linkedin" class="font-size-160p text-weight-semibold"/>
              <q-item-side class="text-center on-top-sm">
                <img src="/statics/_demo/logo.linkedin.svg" style="height:33px">
              </q-item-side>
            </q-item>
            -->
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: QR Code
    -->
    <q-modal id="dialogQRCode" v-model="dialogQRCodeShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout style="background-image: url(/statics/_demo/qrcode_primary.svg)">
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t('QRCODE.LABEL') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn :loading="dialogQRCodeDownloading" :disabled="!channel.qrcode_ref" class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded @click.native="qrcodeDownload">
                {{ $t('DOWNLOAD') }}
                <q-spinner-puff slot="loading" size="20px" />
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogQRCodeShow = false">
                {{ $t('DONE') }}
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-center">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <transition appear enter-active-class="animated fadeInUp animated-d800">
          <img v-if="channel.qrcode_ref" :src="channel.qrcode_ref" style="width:95%;border-radius:2rem;max-width:53vh">
          <div v-else class="layout-padding" style="margin-top: 40%; padding: 0 30%">
            <q-progress indeterminate color="secondary" stripe animate height="12px"/>
          </div>
          </transition>
          <transition appear enter-active-class="animated fadeInUp animated-d800">
          <p v-if="channel.qrcode_ref" class="text-family-brand text-weight-semibold text-center font-size-100p text-attention" style="word-break: break-all;margin-bottom:-20px">
            {{ channel.shortlink }}
            <q-btn flat round icon="ion-link" @click="openURL(channel.shortlink)"/>
            <q-btn flat round icon="ion-refresh" @click="qrcodeCreate"/>
          </p>
          </transition>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Automation (automationEnabled)
    -->
    <q-modal id="dialogAutomation" v-model="dialogAutomationShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout>
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>Automation</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogAutomationShow = false">
                {{ $t('DONE') }}
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-family-brand">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Settings</q-list-header>
            <q-item item link tag="label">
              <q-item-side class="text-center">
                <transition mode="out-in" appear enter-active-class="animated400 bounceIn" leave-active-class="animated400 bounceOut">
                  <q-icon v-if="automationEnabled" key="automation-enabled-on" name="ion-flash" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                  <q-icon v-else key="automation-enabled-off" name="ion-flash-off" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
                </transition>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>Automated States</q-item-tile>
                <q-item-tile sublabel>Enable use of IoT virtual devices</q-item-tile>
              </q-item-main>
              <q-item-side right>
                <q-toggle v-model="automationEnabled" :dark="anyDarkmode"/>
              </q-item-side>
            </q-item>
          </q-list>
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">API Parameters</q-list-header>
            <q-item item>
              <q-item-main>
                <q-item-tile label>Base URI</q-item-tile>
                <q-item-tile sublabel><pre><strong>GET</strong> https://api.mywings.app/</pre></q-item-tile>
              </q-item-main>
            </q-item>
            <q-item item>
              <q-item-main>
                <q-item-tile label>Channel Path</q-item-tile>
                <q-item-tile sublabel><pre>/nservice/{{ product.data.uri }}</pre></q-item-tile>
              </q-item-main>
            </q-item>
            <q-item item>
              <q-item-main>
                <q-item-tile label>Set Device Status</q-item-tile>
                <q-item-tile sublabel><pre>/set/<strong>DEVICE:VIRTUAL_COMPONENT</strong></pre></q-item-tile>
              </q-item-main>
            </q-item>
            <q-item item>
              <q-item-main>
                <q-item-tile label>Session Token <q-chip dense>Dynamic</q-chip></q-item-tile>
                <q-item-tile sublabel><pre>io_session_token={{ $store.state.app.io.io_session_token }}</pre></q-item-tile>
              </q-item-main>
            </q-item>
            <q-item item>
              <q-item-main>
                <q-item-tile label>Access Token <q-chip dense>Dynamic</q-chip></q-item-tile>
                <q-item-tile sublabel><pre>io_access_token={{ $store.state.app.io.io_access_token }}</pre></q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
          <q-list class="card text-system-brand text-weight-medium full-width on-top-lg" no-border link>
            <q-list-header class="text-weight-bold text-grey text-left">Virtual Devices</q-list-header>
            <q-item item v-for="ag in autogroups[0]" :key="ag.id">
              <q-item-side class="text-center">
                <q-icon name="ion-cube" :color="anyDarkmode ? '' : 'blue-grey-10'" size="33px"/>
              </q-item-side>
              <q-item-main>
                <q-item-tile label>{{ ag.name }} ({{ ag.id }})</q-item-tile>
                <q-item-tile sublabel>{{ ag.type }} - {{ ag.description }}</q-item-tile>
                <q-item-tile sublabel>
                  <q-chip class="margin-xs-r" dense v-for="vd in Object.keys(ag.vdevices)" :key="vd">{{ vd }} → {{ ag.vdevices[vd].link }}</q-chip>
                </q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Claim
    -->
    <q-modal id="dialogShare" v-model="dialogClaimShow" position="bottom" class="appLayer dialog-item">
      <q-modal-layout style="background-image: url(/statics/_demo/checkmark.fill.seal_wing.svg)">
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t('CLAIM.BUSINESS') }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn :loading="dialogClaimVerifying" :disabled="!product.data.business.website || product.data.verified" class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="empower-light" text-color="empower" rounded @click.native="verify">
                {{ $t('VERIFY') }}
                <q-spinner-puff slot="loading" size="20px" />
              </q-btn>
            </q-card-main>
          </q-card>
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogClaimShow = false">
                {{ $t('DONE') }}
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top text-family-brand">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <div class="verified text-family-brand flex justify-center no-padding-top capitalize on-bottom-lg">
            <div v-if="product.verified" class="font-size-140p wings-congratulations moveup">
              <span style="margin-right:4px" :class="{ 'text-secondary': !product.verified, 'text-primary': product.verified }" class="text-weight-medium">{{ $t('VERIFIED_' + product.verified_status) }}</span>
              <span class="text-subinfo-l text-weight-medium">on {{ product.verified_timestamp | tformat }}</span>
            </div>
            <div v-else>
              <span class="text-wing text-weight-semibold font-size-140p">
                {{ $t('VERIFIED_NOT') }}
              </span>
            </div>
          </div>
          <template v-if="!product.verified" >
          <hr class="pill">
          <p class="q-title layout-padding no-padding-top text-subinfo slideInDown">
            Claim your business by adding a text tag to your official website.
          </p>
          <p class="q-title layout-padding text-subinfo slideInDown no-padding-top no-margin text-center">
            <img src="/statics/_demo/1.circle.fill.svg" class="margin-auto-lr block opacity-6" style="width: 40px; height: 40px" :class="{ 'filter-invert-100': anyDarkmode }">
            <strong>Your identification tag</strong>
          </p>
          <div class="text-weight-semibold code-container on-bottom">
            &lt;meta name="letsbutterfly-wings-{{ product.data.uri.split('-')[0] }}" content="{{ product.data.uri.split('-')[1] }}"&gt;
          </div>
          <p class="q-title layout-padding text-subinfo slideInDown no-margin text-center">
            <img src="/statics/_demo/2.circle.fill.svg" class="margin-auto-lr block opacity-6 on-left-md" style="width: 40px; height: 40px" :class="{ 'filter-invert-100': anyDarkmode }">
            <strong>Your website</strong>
          </p>
          <div class="text-weight-semibold code-container on-bottom">
            <template v-if="product.data.business.website">
              {{ product.data.business.website }}
            </template>
            <template v-else>
              <q-icon name="ion-warning" class="text-warning on-left" size="22px"/> {{ $t('NOT_FOUND') }}
              <p class="no-margin on-top-default text-protect">
                Update your Google Business account with your website and check again.
              </p>
            </template>
          </div>
          <p class="q-title layout-padding text-subinfo slideInDown no-margin text-center">
            <img src="/statics/_demo/3.circle.fill.svg" class="margin-auto-lr block opacity-6 on-left-md" style="width: 40px; height: 40px" :class="{ 'filter-invert-100': anyDarkmode }">
            <strong>Add ID to website's &lt;head&gt; tag</strong>
          </p>
          <div class="text-weight-semibold code-container on-bottom text-faded">
            &lt;html&gt;
            <br>&lt;head&gt;
            <br> ...
            <br><span class="text-educate">&lt;meta name="letsbutterfly-wings-{{ product.data.uri.split('-')[0] }}" content="{{ product.data.uri.split('-')[1] }}"&gt;</span>
            <br>&lt;/head&gt;
            <br> ...
          </div>
          <p class="q-title layout-padding text-subinfo slideInDown no-margin text-center">
            <img src="/statics/_demo/4.circle.fill.svg" class="margin-auto-lr block opacity-6 on-left-md" style="width: 40px; height: 40px" :class="{ 'filter-invert-100': anyDarkmode }">
            <strong>Tap VERIFY</strong>
          </p>
          </template>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: (Any)
    -->
    <!-- :no-esc-dismiss="this.product.data.channel.online === false" :no-backdrop-dismiss="this.product.data.channel.online === false" -->
    <q-modal id="dialogItem" v-model="dialogPerspectiveShow" position="bottom" class="dialogItem appLayer">
      <q-modal-layout v-if="this.dialogPerspectiveItem" :style="`background-image: url(/statics/_demo/${this.dialogPerspectiveItem.icon}.svg);`">
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t((this.dialogPerspectiveItem.id + '.LABEL').toUpperCase()) }}</q-toolbar-title>
        </q-toolbar>
        <q-toolbar slot="header" inverted class="toolbar-overscroll-shadow">
          <q-card class="q-card-grouped text-center no-margin no-padding no-shadow no-border no-background flex-auto relative-position z-top">
            <q-card-main class="column justify-center full-height">
              <!-- :disabled="this.product.data.channel.online === false" -->
              <q-btn class="margin-auto-lr limit-width-1024 full-width full-height text-family-brand text-weight-semibold uppercase" color="primary-light" text-color="primary" rounded @click.native="dialogPerspectiveShow = false">
                {{ $t('DONE') }}
              </q-btn>
            </q-card-main>
          </q-card>
        </q-toolbar>
        <div class="layout-padding no-padding-top">
          <q-scroll-observable @scroll="toolbarShadowOnOverscroll"/>
          <q-list class="text-family-brand" no-border link :dark="anyDarkmode">
            <p v-if="dialogPerspectiveItem.indicates && dialogPerspectiveItem.indicates.aux"
              :class="{ 'lowercase': !dialogPerspectiveItem.indicates.aux().unformat, 'text-educate': dialogPerspectiveItem.indicates.aux().state === 1, 'text-attention': dialogPerspectiveItem.indicates.aux().state !== 1 }"
              class="text-family-brand text-weight-semibold text-center font-size-160p"
              :style="{ 'word-break': dialogPerspectiveItem.indicates.aux().unformat ? 'normal' : 'break-all' }"
            >
              {{ dialogPerspectiveItem.indicates.aux().text || dialogPerspectiveItem.indicates.aux().value }}
              <q-btn v-if="dialogPerspectiveItem.type === 'link'" flat round icon="ion-link" @click="openURL(dialogPerspectiveItem.indicates.aux().value || dialogPerspectiveItem.indicates.aux().text)"/>
            </p>
            <p class="q-title layout-padding no-padding-top text-subinfo slideInDown" style="padding-bottom:20px">{{ this.dialogPerspectiveItem.label }}</p>
            <q-item v-for="(option, ix) in this.dialogPerspectiveItem.value.options" :key="[option, ix].join('-')" tag="label" :disabled="!checkOptionDependency(option)">
              <q-item-side>
                  <q-radio :disable="!checkOptionDependency(option)" v-model="dialogPerspectiveItem.value.option" :val="ix" @input="dialogPerspectiveItem.signal.update(ix); dialogPerspectiveShow = false" :darkmode="anyDarkmode"/>
              </q-item-side>
              <q-item-main class="text-family-brand">
                <!-- <q-item-tile slot="right">
                  <img v-if="option.icon" :src="option.icon" width="60" class="float-right on-right-sm"/>
                </q-item-tile> -->
                <q-item-tile class="font-size-160p text-weight-semibold" label>{{ $t(option.label) }}</q-item-tile>
                <q-item-tile sublabel lines="3" class="text-weight-medium" style="font-size: 22px">
                  {{ $t(option.sublabel) }}
                </q-item-tile>
              </q-item-main>
            </q-item>
          </q-list>
        </div>
      </q-modal-layout>
    </q-modal>

    <!--
      MODAL: Removing Product
    -->
    <q-modal v-model="dialogRemovingShow" minimized no-esc-dismiss no-backdrop-dismiss>
      <q-modal-layout>
        <q-toolbar slot="header" inverted>
          <q-toolbar-title>{{ $t(getEcosystemLabel('DELETE.DELETING')) }}</q-toolbar-title>
        </q-toolbar>
        <div class="layout-padding">
          <q-progress indeterminate color="secondary" stripe animate height="12px"/>
        </div>
      </q-modal-layout>
    </q-modal>

    <l-footer simple/>
  </div>
</template>

<script>
import LFooter from 'components/l-footer'
import { axiosLIO } from 'plugins/axios'
import { openURL, animate, easing } from 'quasar'
import moment from 'moment'
import nformat from 'vue-filter-number-format'
import PubNub from 'pubnub'

import Wings from '../services/wings.js'

export default {
  name: 'PageProductsEdit',
  props: ['auth', 'authenticated', 'lang', 'ecosystem', 'anyDarkmode'],
  components: {
    LFooter
  },
  data () {
    return {
      pn: null,
      channel: {
        shortlink: null,
        qrcode_blob: null,
        qrcode_ref: null,
        presense_count: 0
      },
      ready: false,
      dialogPerspectiveItem: null,
      dialogPerspectiveShow: false,
      dialogRemovingShow: false,
      dialogQRCodeShow: false,
      dialogQRCodeDownloading: false,
      dialogShareShow: false,
      dialogClaimShow: false,
      dialogClaimVerifying: false,
      dialogRequestShow: false,
      dialogRequestIndex: null,
      datagroups: Wings.datagroups(this),
      autogroups: Wings.autogroups(this),
      dialogAutomationShow: false,
      automationEnabled: false
    }
  },
  filters: {
    nformat: nformat,
    tformat: function (val) {
      return moment(val).format('MMMM Do, YYYY')
    }
  },
  beforeCreate () {
    try {
      document.querySelector('#appHeader').classList.add('animate-hide')
    } catch (e) {}
  },
  beforeMount () {
    if (this.product) {
      this.ready = true
      this.channelInit()
      this.datafieldsInit()
      this.channelIotInit()
      setTimeout(() => {
        document.querySelector('#productsEditContent').classList.remove('no-pointer-events')
      }, 400)
    } else {
      console.error('No data found!')
      this.$router.push(`/${this.$store.state.app.io.ecosystem_id}-demo`)
    }
    window.addEventListener('resize', () => {
      this.dialogItemAdjustFit()
    })
  },
  mounted () {
    try {
      document.querySelector('#productsEditHeader').classList.add('no-border')
    } catch (e) {}
    setTimeout(() => {
      try {
        document.querySelector('#productsEditHeader').classList.add('no-shadow')
      } catch (e) {}
    }, 800)
    setTimeout(() => {
      try {
        document.querySelector('#productsEditHeader').classList.add('animate-show')
      } catch (e) { } finally {}
    }, 400)
    document.querySelector('.q-layout-page-container').classList.add('q-layout-page-container-padding')
    this.$emit('stopAjaxBar')
    // animate content
    let el = this.$refs['productsEditContent']
    if (el) {
      animate.start({
        from: 0,
        to: 1,
        duration: 800,
        easing: easing.accelerate,
        apply (pos) {
          el.style.opacity = pos
        }
      })
    }
  },
  created () {
    document.querySelector('#q-app > .q-loading-bar.top.bg-primary').hidden = true
    setTimeout(() => {
      if (this.product &&
          this.product.data &&
          this.product.data.channel &&
          this.product.data.channel.online === false
      ) {
        setTimeout(() => this.processCardSelection('services-0-online-0'), 1000)
      }
    }, 600)
  },
  beforeDestroy () {
    document.querySelector('.q-layout-page-container').classList.remove('q-layout-page-container-padding')
  },
  computed: {
    ecosystem_id () {
      return this.ecosystem.ecosystem_id
    },
    ecosystem_id_t () {
      return this.ecosystem.ecosystem_id.toUpperCase()
    },
    product: {
      get () {
        console.log(this.$store.getters['app/getProducts'])
        console.log(this.$route.params.id)
        console.log('product::GET', this.$store.getters['app/getProducts'].list[this.$route.params.id])
        return this.$store.getters['app/getProducts'].list[this.$route.params.id]
      },
      set (product) {
        console.log('product::SET', product)
        this.$store.commit('app/updateProductPayload', this.$route.params.id, product)
      }
    },
    productFriendlyName () {
      return this.product.data.uri.split('-')[0].replace(/_/g, ' ')
        .trim().toLowerCase().replace(/\w\S*/g, (w) => (w.replace(/^\w/, (c) => c.toUpperCase())))
    },
    productFullURI () {
      return [document.location.origin, '/channel/', this.product.data.uri].join('')
    },
    requests: {
      get () {
        return this.$store.state.app.requests
      },
      set (val) {
        this.$store.state.app.requests = val
      }
    }
  },
  methods: {
    openURL,
    getEcosystemLabel (l) {
      return `E.${this.ecosystem_id_t}.${l}`
    },
    formatValue (num) {
      return nformat(num, '0,0')
    },
    exit () {
      document.querySelector('#productsEditHeader').classList.remove('animate-show')
      document.querySelector('#appHeader').classList.remove('no-shadow')
      document.querySelector('#appHeader').classList.remove('animate-hide')
      // animate content
      let el = this.$refs['productsEditContent']
      animate.start({
        from: 1,
        to: 0,
        duration: 400,
        easing: easing.accelerate,
        apply (pos) {
          el.style.opacity = pos
        }
      })
      setTimeout(() => this.$router.go(-1), 400)
    },
    scrolled (scroll) {
      let scrollingTriggerThreshold = 100
      if (Math.abs(scroll.inflexionPosition - scroll.position) >= scrollingTriggerThreshold) {
        if (scroll.direction === 'down' && scroll.position >= scrollingTriggerThreshold) {
          // minimze header
          document.querySelector('#productsEditHeader').classList.add('mini')
        } else if (scroll.direction === 'up') {
          if ((scroll.position <= 200) || (scroll.inflexionPosition - scroll.position >= 300)) {
            // expand header
            document.querySelector('#productsEditHeader').classList.remove('mini')
          }
        }
      }
      if (scroll.direction === 'down' && scroll.position >= 1) {
        document.querySelector('#productsEditHeader').classList.remove('no-shadow')
      } else if (scroll.direction === 'up' && scroll.position <= 10) {
        document.querySelector('#productsEditHeader').classList.add('no-shadow')
      }
    },
    toolbarShadowOnOverscrollTarget () {
      let modalTarget = null
      document.querySelectorAll('.modal').forEach((o, i) => {
        if (o.clientHeight !== 0) modalTarget = o
      })
      return modalTarget
    },
    toolbarShadowOnOverscrollClear (timeout = 10) {
      setTimeout(() => {
        try {
          let modalTarget = this.toolbarShadowOnOverscrollTarget()
          if (modalTarget) {
            modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
          }
        } catch (e) {
          console.log(e)
        }
      }, timeout)
    },
    toolbarShadowOnOverscroll (scroll) {
      let modalTarget = this.toolbarShadowOnOverscrollTarget()
      if (modalTarget) {
        if (scroll.direction === 'down' && scroll.position >= 1) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.add('toolbar-overscroll-shadow-show')
        } else if (scroll.direction === 'up' && scroll.position <= 10) {
          modalTarget.querySelector('.toolbar-overscroll-shadow').classList.remove('toolbar-overscroll-shadow-show')
        }
      }
    },
    cleanURL (url) {
      if (!url) return ''
      return url
        .replace('https://', '')
        .replace('http://', '')
        .replace('www.', '')
        .replace(/\/$/, '')
    },
    shareSheetSupport () {
      console.log(':: shareSheetSupport: ', !!navigator.share)
      return !!navigator.share
    },
    shareSheet () {
      if (this.shareSheetSupport()) {
        let sharePayload = {
          title: ['Wings', this.productFriendlyName, this.product.data.business.address.full].join(' · '),
          // text: this.product.data.business.address.full,
          url: this.productFullURI
        }
        navigator.share(sharePayload)
          .then(() => console.log('Successful share'))
          .catch((error) => console.log('Error sharing', error))
      } else {
        console.log('::SHARE: -- error')
      }
    },
    automationSettings  () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogAutomationShow = !this.dialogAutomationShow
    },
    share () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogShareShow = !this.dialogShareShow
    },
    verify () {
      this.dialogClaimVerifying = true
      //
      console.log('🦋 [product] verify')
      axiosLIO.post('/product/verify', {
        id: this.product.id
      }).then((res) => {
        console.log(res)
        try {
          console.log(res.data.data.verified)
          this.product.verified = res.data.data.verified
          this.product.verified_status = res.data.data.verified_status
          this.product.verified_timestamp = res.data.data.verified_timestamp
          if (!res.data.data.verified) {
            this.$q.dialog({
              title: this.$t('CLAIM.VERIFY_TITLE'),
              message: this.$t('CLAIM.VERIFY_MESSAGE_NO'),
              ok: this.$t('OK')
            })
          }
        } catch (e) {}
        this.dialogClaimVerifying = false
      }).catch(err => {
        console.log(err)
        this.dialogClaimVerifying = false
      })
    },
    claim () {
      this.toolbarShadowOnOverscrollClear()
      this.dialogClaimShow = !this.dialogClaimShow
    },
    openPublicChannel () {
      openURL(this.productFullURI)
    },
    perspective (card) {
      let ids = card.dataset.index.split('-')
      this.dialogPerspectiveItem = this.datagroups[1 * ids[1]].datafields[1 * ids[3]]
      this.dialogPerspectiveShow = true
      this.toolbarShadowOnOverscrollClear()
      console.log(this.dialogPerspectiveItem)
    },
    channelToggleSignal () {
      if (!this.product.data.channel.online) this.product.data.channel.online = false
      console.log(':: online: ', this.product.data.channel.online)
      if (this.product.data.channel.online) this.channelGoOffline()
      else this.channelGoOnline()
    },
    channelUUID () {
      try {
        return 'pn_' + this.product.data.uri
      } catch (e) {
        return null
      }
    },
    requestView (requestIndex, request) {
      this.dialogRequestIndex = requestIndex
      this.dialogRequestShow = true
      console.log(requestIndex, request)
    },
    requestStatusUpdate (status) {
      this.requests[this.dialogRequestIndex].status = status
      if (status === 'finalize') {
        // remove it from the channel
        if (this.requests.length === 1) {
          this.requests = []
        } else {
          let _requests = this.requests
          _requests.splice(this.dialogRequestIndex, 1)
          this.requests = _requests
        }
      }
      // publish update
      this.channelRequestsPublish()
      setTimeout(() => {
        this.dialogRequestShow = false
      }, 200)
    },
    channelRequestsAdd (request) {
      request.status = 'in-review'
      request.location = 'concierge'
      // create an ID for the service request
      let alphas = 'ABCDEFGHJKLMNPRSTXY'.split('')
      request.id = [
        'W', alphas[Math.floor(Math.random() * Math.floor(alphas.length))],
        Date.now().toString().substr(-2)
      ].join('')
      // add request
      this.requests.push(request)
      this.channelRequestsPublish()
      // simulation: mutate the request
      // setTimeout(() => {
      //   const _r = this.requests.length - 1
      //   setTimeout(() => {
      //     this.requests[_r].status = 'in-progress'
      //     this.channelRequestsPublish()
      //     setTimeout(() => {
      //       this.requests[_r].status = 'dropping-off'
      //       this.channelRequestsPublish()
      //       setTimeout(() => {
      //         this.requests[_r].status = 'completed'
      //         this.channelRequestsPublish()
      //         setTimeout(() => {
      //           // remove it from the hive
      //           if (this.requests.length === 1) {
      //             this.channelRequestsClear()
      //           } else {
      //             let _requests = this.requests
      //             _requests.splice(_r, 1)
      //             this.requests = _requests
      //           }
      //           this.channelRequestsPublish()
      //         }, 10000)
      //       }, 10000)
      //     }, 10000)
      //   }, 10000)
      // }, 1)
    },
    channelRequestsClear () {
      this.requests = []
      this.channelRequestsPublish()
    },
    channelRequestsPublish () {
      this.product.data.channel.requests = this.requests
      this.channelPublish()
      this.mutateProduct(this.product.id, this.product.data, 'SIGNAL: SEND: ', () => {})
    },
    channelIotInit () {
      let _this = this
      console.log(':: Channel: IoT: Init')
      setInterval(() => {
        console.log(':: Channel: IoT: Check')
        if (!this.automationEnabled) {
          console.log(':: Channel: IoT: Check: Skipped')
          return
        }
        axiosLIO.get('/nservice/aero_coffee_roasters-cae48f2d9128cf26f3567175394b1249/get', {
          // nothing to pass
        }).then((res) => {
          try {
            let cs = res.data.data.channel_states
            if (cs) {
              for (let vd in cs) {
                let vk = vd, vv = cs[vd]
                console.log(':: Channel: IoT: Updates: ', vk, vv)
                //
                let device = vk.split(':')[0]
                let deviceVkData = _this.autogroups[0][device].vdevices[vk]
                let link = deviceVkData.link
                //
                console.log(':: Channel: IoT: Updates: Links: ', link)
                // make and publish changes
                // _this.product.data.channel[link] = vv
                // _this.channelPublish()
                // _this.mutateProduct(_this.product.id, _this.product.data,
                //   `SIGNAL: [IoT:${vk} ${link}] ` + (vv ? 'On' : 'Off'), () => {})
                // reflect the UI of the changes
                console.log(':: Channel: IoT: Post-Update ', this.datagroups)
                console.log(':: Channel: IoT: Post-Update ', this.datagroups[deviceVkData.datagroup])
                console.log(':: Channel: IoT: Post-Update ', this.datagroups[deviceVkData.datagroup].datafields[deviceVkData.datafield])
                this.datagroups[deviceVkData.datagroup].datafields[deviceVkData.datafield].value.option = vv
                this.datagroups[deviceVkData.datagroup].datafields[deviceVkData.datafield].signal.update(vv)
                this.$forceUpdate()
              }
            }
          } catch (e) {
            console.log(':: Channel: IoT: Error: ', e)
          }
        })
      }, 5000)
    },
    channelInit () {
      if (!this.pn) {
        console.log(':: Channel: INIT')
        this.pn = new PubNub({
          subscribeKey: 'sub-c-6ef8f7b4-860c-11e9-99de-d6d3b84c4a25',
          publishKey: 'pub-c-4a7e4814-55a0-4e5f-98d7-eba6d8e92cd3',
          uuid: this.product.data.uri,
          ssl: true,
          autoNetworkDetection: true
        })
        console.log(':: Channel: LISTENING...')
        this.pn.addListener({
          state: (s) => {
            console.log('state', s)
          },
          message: (m) => {
            console.log(':: Channel: [', m.channel, '] UPDATE: ')
            if (m.channel.split('.').pop() === 'requests') {
              console.log(m)
              this.channelRequestsAdd(m.message)
            } else {
              console.log(m)
            }
          },
          presence: (p) => {
            // handle presence
            // var action = p.action // Can be join, leave, state-change or timeout
            // var channelName = p.channel // The channel for which the message belongs
            // var occupancy = p.occupancy // No. of users connected with the channel
            // var state = p.state // User State
            // var channelGroup = p.subscription //  The channel group or wildcard subscription match (if exists)
            // var publishTime = p.timestamp // Publish timetoken
            // var timetoken = p.timetoken  // Current timetoken
            // var uuid = p.uuid // UUIDs of users who are connected with the channel
            console.log('presence', p)
            this.channel.presence = p
            this.channel.presense_count = p.occupancy
            // TODO: check if multiple admins are connecting
            console.log(p.uuid)
          }
        })
        console.log(':: Channel: SUBSCRIBING...', [this.channelUUID(), this.channelUUID() + '.requests'])
        this.pn.subscribe({
          channels: [this.channelUUID(), this.channelUUID() + '.requests'],
          withPresence: true
        })
        this.pn.hereNow({
          channels: [this.channelUUID()],
          includeUUIDs: true,
          includeState: true
        }, (status, response) => {
          this.channel.presense_count = (response.totalOccupancy > 0) ? response.totalOccupancy - 1 : 0
        })
      }
    },
    channelPublish () {
      console.log(':: Channel: PUBLISHING...', this.channelUUID())
      console.log(this.product.data.channel)
      this.pn.publish({
        channel: this.channelUUID(),
        sendByPost: true,
        storeInHistory: true,
        message: this.product.data.channel
      }, (status, response) => {
        if (status.error) {
          console.log(status)
        } else {
          console.log('message Published w/ timetoken', response.timetoken)
          console.log(response)
        }
      })
    },
    channelGoOnline () {
      this.product.data.channel.online = true
      this.channelPublish()
      this.mutateProduct(this.product.id, this.product.data, 'SIGNAL: ' +
        (this.product.data.channel.online ? 'On' : 'Off'), () => {})
    },
    channelGoOffline () {
      this.product.data.channel.online = false
      this.channelPublish()
      this.mutateProduct(this.product.id, this.product.data, 'SIGNAL: ' +
        (this.product.data.channel.online ? 'On' : 'Off'), () => {})
    },
    remove () {
      // confirm
      this.$q.dialog({
        title: this.$t(this.getEcosystemLabel('DELETE.TITLE_QUERY')),
        message: this.$t(this.getEcosystemLabel('DELETE.MESSAGE_QUERY')),
        ok: this.$t('YES'),
        cancel: this.$t('NO')
      }).then(() => {
        // delete
        this.archive()
      }).catch(() => {
        // ignore
      })
    },
    archiveProduct (id, done) {
      console.log('🦋 [product] archive')
      axiosLIO.post('/product/archive', {
        id
      }).then((res) => {
        done(res)
      }).catch(err => {
        done(err)
      })
    },
    mutateProduct (id, payload, audit, done) {
      console.log('🦋 [product] mutate: ', payload)
      axiosLIO.post('/product/mutate', {
        id,
        payload: JSON.stringify(payload),
        audit
      }).then((res) => {
        done(res)
      }).catch(err => {
        done(err)
      })
    },
    archive () {
      this.dialogRemovingShow = true
      this.archiveProduct(this.product.id, () => {
        setTimeout(() => {
          this.dialogRemovingShow = false
          // reset local storage to force a reload of data
          this.$store.state.app.products.list = []
          this.$router.replace('/business')
        }, 400)
      })
    },
    shortlinkCreate (cb) {
      console.log(':: shortlinkCreate')
      // 1) shorten url
      const url = encodeURI(this.productFullURI.replace('http://localhost:8080', 'https://mywings.app'))
      const BitlyClient = require('bitly').BitlyClient
      const bitly = new BitlyClient('329490bd2c35f2c747df8f26cbcd88cd24972c22', {
        domain: 'ltsbtrf.ly'
      })
      bitly
        .shorten(url)
        .then((result) => {
          // 2) save shortened url
          this.shortlinkSave(result.link)
          if (cb) cb()
        }).catch((error) => {
          console.error(error)
        })
    },
    shortlinkSave (shortLink) {
      console.log(':: shortlinkSave')
      this.product.data.shortlink = shortLink
      this.channel.shortlink = this.product.data.shortlink
      this.mutateProduct(this.product.id, this.product.data, `IDS.SHORTLINK: ${this.product.data.shortlink}`, () => {})
      return true
    },
    qrcodeCreate () {
      console.log(':: qrcodeCreate')
      // 1) generate qr code
      this.$axios.get('https://qrcode-monkey.p.rapidapi.com/qr/custom', {
        headers: {
          'content-type': 'application/octet-stream',
          'x-rapidapi-host': 'qrcode-monkey.p.rapidapi.com',
          'x-rapidapi-key': 'ae7bf8867fmsh1b89822b326e4cep1ec9eejsnfa0566b242f6'
        },
        params: {
          size: 1000,
          file: 'svg',
          config: {
            body: 'circle-zebra-vertical',
            eye: 'frame1',
            eyeBall: 'ball1',
            bodyColor: '#000000',
            bgColor: '#ffffff',
            logo: 'https://res.cloudinary.com/letsbutterfly/image/upload/c_pad,b_auto:predominant,fl_preserve_transparency/v1672307562/wings-app/assets/WINGS_icon-wide-contrast.jpg',
            logoMode: 'clean',
            erf1: ['fh'],
            erf3: ['fh', 'fv'],
            eye1Color: '#53585F',
            eye2Color: '#53585F',
            eye3Color: '#53585F',
            eyeBall1Color: '#FF0091',
            eyeBall2Color: '#FF0091',
            eyeBall3Color: '#FF0091',
            brf1: ['fh'],
            brf3: ['fh', 'fv']
          },
          data: encodeURI(this.product.data.shortlink)
        }
      }).then((response) => {
        console.log(response)
        this.channel.qrcode_blob = 'data:image/svg+xml;utf8,' + response.data
        // 2) upload data to cloudinary
        this.qrcodeSave(response.data)
      }).catch((error) => {
        console.log(error)
      })
    },
    qrcodeSave (svg) {
      console.log(':: qrcodeSave')
      let self = this
      const blob = new Blob([svg], {type: 'image/svg+xml'})
      let xhr = new XMLHttpRequest()
      let fd = new FormData()
      xhr.open('POST', 'https://api.cloudinary.com/v1_1/letsbutterfly/upload', true)
      xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest')

      xhr.upload.addEventListener('progress', function (e) {
        var progress = Math.round((e.loaded * 100.0) / e.total)
        console.log(`fileuploadprogress data.loaded: ${e.loaded}, data.total: ${e.total}, progress: ${progress}`)
      })

      xhr.onreadystatechange = function (e) {
        if (xhr.readyState === 4 && xhr.status === 200) {
          // File uploaded successfully
          var response = JSON.parse(xhr.responseText)
          console.log(response)
          // update data and mutate
          let qrcodeRef = response.secure_url
          self.channel.qrcode_ref = qrcodeRef
          self.product.data.qrcode_ref = qrcodeRef
          self.mutateProduct(self.product.id, self.product.data, `IDS.QRCODE_REF: ${self.product.data.qrcode_ref}`, () => {})
          return true
        } else {
          console.log(e)
        }
      }

      let tags = window.location.host.replace('.app', '')
      if (tags.indexOf(':') >= 0) {
        tags = 'local-dev'
      }

      fd.append('upload_preset', 'wings-app-499472be-0966-4123-9f82-9127026c51b3')
      fd.append('tags', tags)
      fd.append('file', blob)
      xhr.send(fd)
    },
    forceFileDownload (response) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', `qrcode_${this.product.data.uri.split('-')[0]}.png`)
      document.body.appendChild(link)
      link.click()
      this.dialogQRCodeDownloading = false
    },
    qrcodeDownload () {
      this.dialogQRCodeDownloading = true
      this.$axios.get(this.channel.qrcode_ref.replace('.svg', '.png'), {
        responseType: 'arraybuffer'
      }).then(response => {
        this.forceFileDownload(response)
      }).catch(() => {
        console.log('error occured')
        this.dialogQRCodeDownloading = false
      })
    },
    qrcode () {
      if (!this.channel.shortlink) {
        this.channel.shortlink = this.product.data.shortlink
      }
      if (!this.channel.qrcode_ref) {
        this.channel.qrcode_ref = this.product.data.qrcode_ref
      }
      this.dialogQRCodeShow = !this.dialogQRCodeShow
      if (!this.product.data.shortlink) {
        this.shortlinkCreate(() => {
          this.qrcodeCreate()
        })
      } else if (!this.channel.qrcode_ref) {
        this.qrcodeCreate()
      }
    },
    datafieldsInit () {
      this.datagroups.forEach((g, i) => {
        g.datafields.forEach((f, j) => {
          // initialize values
          if (f.valueType) {
            if (f.valueType.name === 'Boolean') {
              this.datagroups[i].datafields[j].value = {
                option: 0,
                options: [{
                  bTrue: true,
                  label: this.$t('YES')
                }, {
                  bTrue: false,
                  label: this.$t('NO')
                }]
              }
            }
          }
          // initialize option
          if (typeof this.product.data.channel[f.id] === 'undefined') {
            this.product.data.channel[f.id] = this.datagroups[i].datafields[j].value.option || 0
          } else {
            this.datagroups[i].datafields[j].value.option = this.product.data.channel[f.id]
          }
          // create signals
          this.datagroups[i].datafields[j].signal = {
            // create update method
            update: (option) => {
              this.product.data.channel[f.id] = option
              this.channelPublish()
              this.mutateProduct(this.product.id, this.product.data, `SIGNAL.${f.id}: ${this.product.data.channel[f.id]}`, () => {})
            },
            // create descriptor
            bCheck: () => {
              let bTrue = this.datagroups[i].datafields[j].value.options[this.datagroups[i].datafields[j].value.option || 0].bTrue
              return (bTrue === true || bTrue === false) ? bTrue : null
            },
            descriptor: () => {
              try {
                return this.datagroups[i].datafields[j].value.options[this.datagroups[i].datafields[j].value.option || 0].label
              } catch (e) {
                return null
              }
            }
          }
        })
      })
    },
    checkOptionDependency (option) {
      if (option.dependsOn) {
        try {
          return this.product.data.channel[option.dependsOn] === 0
        } catch (er) {}
      }
      return true
    },
    dialogItemAdjustFit (percentage = 80) {
      let innerHeight = window.innerHeight
      let dialogItemContent = document.querySelector('.dialogItem .modal-content')
      if (dialogItemContent) {
        try {
          dialogItemContent.style.setProperty('height', `${(innerHeight * percentage) / 100}px`, 'important')
        } catch (err) {}
      }
    },
    processCardSelection (card) {
      if (typeof card !== 'object') {
        let id = card
        card = this.$refs[`product-card-${id}`].$el || this.$refs[`product-card-${id}`][0].$el
      }
      this.perspective(card)
      setTimeout(() => {
        // reset shadow scroll
        // document.querySelector('#itemEditHeader').classList.remove('shadow-overscroll-show')
        // adjust height per viewport
        this.dialogItemAdjustFit()
      }, 10)
    },
    setCardIntent (obj, id, cb) {
      let card = this.$refs[`product-card-${id}`].$el || this.$refs[`product-card-${id}`][0].$el
      // ignore intent if card is disabled
      if (card.attributes.disabled) return
      // handle card
      if (obj.isFirst) {
        card.classList.add('intent')
      } else if (obj.isFinal) {
        if (card.classList.contains('intent')) {
          card.classList.remove('intent')
          if (Math.abs(obj.offset.x) < 50 && Math.abs(obj.offset.y) < 50) {
            // call handler
            setTimeout(function () {
              (cb)(card)
            }, 1)
          }
        }
      } else {
        if (card.classList.contains('intent')) {
          if (Math.abs(obj.offset.x) > 50 || Math.abs(obj.offset.y) > 50) {
            card.classList.remove('intent')
          }
        }
      }
    }
  }
}
</script>
<style lang="stylus">
.q-layout-page-container.q-layout-transition.q-layout-page-container-padding
  padding-top 168px !important
#productsEditHeader
  top -200px
  transition all 400ms ease-in-out
&.animate-show
  top 0 !important
@media (min-width: 1200px)
  #productsEditContent
    width 1200px
.dialog-item
  .q-modal-layout
    background-repeat no-repeat
    background-position 130% bottom
    background-color rgba(255, 255, 255, 0.96)
    background-blend-mode color
    background-size 50%
  .q-option-inner
    i
      font-size 160%

.q-card[data-index*='-doordash-']
  .q-card-primary
    background-size 132px
    background-repeat no-repeat
    background-position 95% 9px
    background-image url(/statics/_demo/services/doordash.svg)

.q-card[data-index*='-grubhub-']
  .q-card-primary
    background-size 101px
    background-repeat no-repeat
    background-position 97% 51px
    background-image url(/statics/_demo/services/grubhub.png)

.q-card[data-index*='-ubereats-']
  .q-card-primary
    background-size 101px
    background-repeat no-repeat
    background-position 95% 64px
    background-image url(/statics/_demo/services/ubereats.svg)

.q-card[data-index*='-postmates-']
  .q-card-primary
    background-size 72px
    background-repeat no-repeat
    background-position 98% 40px
    background-image url(/statics/_demo/services/postmates.svg)

</style>
